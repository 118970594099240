<div class="general-status" *ngFor="let atm of _atms?.atms">
  <div class="titles-container">
    <h1 class="title-atm">
      <span class="text">{{atm.name}}</span>
    </h1>
    <h1 class="title-graph">
      <span class="text">{{ title }}</span>
    </h1>
  </div>
  <div class="general-status-container">
    <div class="general-card monitoring-atm">
      <div class="atm-radar-container">
        <div class="atm-widget">
          <atm-status-widget 
            [size]="'large'"
            [batteryStatus]="atm.sensorBattery?.status"
            [sensorVault]="atm.sensorVault?.status"
            [sensorNet]="atm.sensorNet?.status"
            [sensorFakeDoor]="atm.sensorFakeDoor?.status"
            [sensorVault]="atm.sensorVault?.status" 
            [sensorTray]="atm.sensorTray?.status">
          </atm-status-widget>
        </div>
        <div class="radar-widget">
          <widget-radar 
            [sensorInput]="atm.sensorRadar?.lastData?.value" 
            [equipmentId]="atm.id.split(':')[1] + '-radar'">

          </widget-radar>
        </div>
      </div>
    </div>
    <div class="general-card monitoring-data" [ngClass]="isAlarma?'alarm-card':''">
      <div class="monitoring-data-chart">
        <div class="monitoring-data-chart-chart">
          <div *ngIf="atm.sensorBattery?.selectVariable" class="monitoring-chart">
            <app-chart-dashboard [data]="atm.sensorBattery?.lastData" [equipmentId]="atm.id + 'battery'" [variableId]="atm.sensorBattery?.id"></app-chart-dashboard>
          </div>
          <div *ngIf="atm.sensorNet?.selectVariable" class="monitoring-chart">
            <app-chart-dashboard [data]="atm.sensorNet?.lastData" [equipmentId]="atm.id + 'net'" [variableId]="atm.sensorNet?.id"></app-chart-dashboard>
          </div>
          <div *ngIf="atm.sensorVault?.selectVariable" class="monitoring-chart">
            <app-chart-dashboard [data]="atm.sensorVault?.lastData" [equipmentId]="atm.id + 'vault'" [variableId]="atm.sensorVault?.id"></app-chart-dashboard>
          </div>
          <div *ngIf="atm.sensorTray?.selectVariable" class="monitoring-chart">
            <app-chart-dashboard [data]="atm.sensorTray?.lastData" [equipmentId]="atm.id + 'tray'" [variableId]="atm.sensorTray?.id"></app-chart-dashboard>
          </div>
          <div *ngIf="atm.sensorFakeDoor?.selectVariable" class="monitoring-chart">
            <app-chart-dashboard [data]="atm.sensorFakeDoor?.lastData" [equipmentId]="atm.id + 'tray'" [variableId]="atm.sensorFakeDoor?.id"></app-chart-dashboard>
          </div>
          <div *ngIf="atm.sensorGeo?.selectVariable" class="monitoring-map-chart">
            <app-location-map [long]="atm.sensorGeo?.lastData?.value.split('&')[1]" [lat]="atm.sensorGeo?.lastData?.value.split('&')[0]" [canvasId]="'map-' + atm.id"></app-location-map>
          </div>
        </div>
      </div>
    </div>
    <div class="general-card monitoring-sensors" [ngClass]="isAlarma?'alarm-card':''">
      <div class="monitoring-sensors-sensor">
        <div>
          <app-sensors
            (sensorClicked)="handleSensorClicked($event, atm)"
            [gpsStatus]="isAlarma"
            [batteryStatus]="atm.sensorBattery?.status"
            [netStatus]="atm.sensorNet?.status"
            [fakeDoorStatus]="atm.sensorFakeDoor?.status"
            [VaultStatus]="atm.sensorVault?.status"
            [cpuDoorStatus]="atm.sensorTray?.status"
            [equipmentInfo]="atm"
            [sensorsTitle]="sensors"
            [atmName] = "atm.name">
          </app-sensors>
        </div>

        <div class="reporteria">
          <app-reports [atm]="atm"></app-reports>
        </div>
      </div>
    </div>
  </div>
</div>