import { Component, Input, OnInit } from '@angular/core';
import * as maplibregl from 'maplibre-gl';
import { AlarmsService } from 'src/app/services/mqtt/alarms.service';

@Component({
  selector: 'app-location-map',
  templateUrl: './location-map.component.html',
  styleUrls: ['./location-map.component.scss'],
})
export class LocationMapComponent implements OnInit {
  @Input() set long(coordinate: number) {
    if (coordinate && this.lastLong != coordinate) {
      this._long = coordinate == -26 || coordinate == 0 || coordinate == -37 ? this.lastLong : coordinate;
      this.lastLong = coordinate;
      // this.recenterMap();
    }
  }
  @Input() set lat(coordinate: number) {
    if (coordinate && this.lastLat != coordinate) {
      this._lat = coordinate == -25 || coordinate == 0 || coordinate == -37 ? this.lastLat : coordinate;
      this.lastLat = coordinate;
      // this.recenterMap();
    }
  }
  @Input() canvasId!: string;
  map!: maplibregl.Map;
  lastLong: number = -99.1856378333;
  lastLat: number = 19.2966396667;
  _long: number = -99.1856378333;
  _lat: number = 19.2966396667;

  currentMarker:any;
  ngOnInit() {}

  ngAfterViewInit(): void {
    const apiKey =
      'v1.public.eyJqdGkiOiJmYjFjMWY4OC04MTE5LTQ0YjUtYjU3MS1jNGNlMGVjODJkYWEifTGmzj9YJCAzmSRGAcommQG-y4t_DZjXtpZ9rO4iK55g5WoD7fBvWwJES5FiPDD0saCSRuvf0ZXL1VL9TOPK1aRlpqSpCqfSb_WEfWCdYzHVEvU4f_TRWXMvSc-k9hORkg7h6ZTfj-cA3xmDwBRof8EMB6UyaLF300NM4lxYvmfF-P6cs343aNViY1fTr196rBcKxbpQrzTJ-tMLkDApLCpQg0bA1LMdH12QoCL5-bCuB0NrL0O59N4G2lMa9dPf0dfs2YQfKfM2WsuEFLJm41ZZBcLipRvh_EJILRP9QtOGFuWbpaloAkItimUTKCecSNkON3TLSfFt0Rv-_RxIGIY.ZWU0ZWIzMTktMWRhNi00Mzg0LTllMzYtNzlmMDU3MjRmYTkx';
    const mapName = 'esri-navigation-sauron';
    const region = 'us-east-1';
    this.map = new maplibregl.Map({
      container: this.canvasId,
      style: `https://maps.geo.${region}.amazonaws.com/maps/v0/maps/${mapName}/style-descriptor?key=${apiKey}`,
      center: [this._long, this._lat],
      zoom: 15,
    });
    this.map.addControl(new maplibregl.NavigationControl(), 'top-left');
    this.currentMarker = new maplibregl.Marker().setLngLat([this._long, this._lat]).addTo(this.map);

    this.map.addControl(
      new maplibregl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
        showAccuracyCircle: false,
        fitBoundsOptions: {
          maxZoom: 15,
        },
        showUserLocation: true,
      })
    );

    this.map.addControl(new maplibregl.FullscreenControl(), 'top-right');
    this.map.addControl(new maplibregl.ScaleControl(), 'bottom-left');

    /* Eliminamos el btn de atributos localizado en la esquina inferior derecha */
    const attribControl = document.querySelector(
      '.maplibregl-ctrl.maplibregl-ctrl-attrib.maplibregl-compact.maplibregl-compact-show'
    );
    if (attribControl) {
      attribControl.remove();
    }

    /* Instrucciones para cambiar el color del Pin Marker */
    const markerDiv = document.querySelector('.maplibregl-marker');
    if (markerDiv) {
      const pathElement = markerDiv.querySelector('g > g > path') as SVGElement;
      if (pathElement) {
        pathElement.style.fill = 'black';
      }
    }
    this.map.on('load', () => {
      this.map.addSource('geocerca', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [-99.1858656, 19.29861035569436],
                    [-99.18553516502554, 19.298582906719304],
                    [-99.18521477047284, 19.298501393843832],
                    [-99.18491415165167, 19.29836829387171],
                    [-99.18464244292315, 19.298187651095105],
                    [-99.18440790013138, 19.297964954396743],
                    [-99.1842176497404, 19.297706970455657],
                    [-99.18407747230012, 19.297421538126716],
                    [-99.18399162682078, 19.297117330243648],
                    [-99.18396272139042, 19.29680359008531],
                    [-99.18399163396364, 19.296489850514135],
                    [-99.18407748572432, 19.29618564432173],
                    [-99.18421766782676, 19.29590021458303],
                    [-99.18440792069843, 19.295642233819343],
                    [-99.1846424634902, 19.2954195405023],
                    [-99.18491416973805, 19.295238900903097],
                    [-99.18521478389704, 19.295105803521214],
                    [-99.1855351721684, 19.295024292336404],
                    [-99.1858656, 19.294996843948503],
                    [-99.1861960278316, 19.295024292336404],
                    [-99.18651641610296, 19.295105803521214],
                    [-99.18681703026195, 19.295238900903097],
                    [-99.18708873650979, 19.2954195405023],
                    [-99.18732327930157, 19.295642233819343],
                    [-99.18751353217324, 19.29590021458303],
                    [-99.18765371427568, 19.29618564432173],
                    [-99.18773956603636, 19.296489850514135],
                    [-99.18776847860958, 19.29680359008531],
                    [-99.18773957317921, 19.297117330243648],
                    [-99.18765372769988, 19.297421538126716],
                    [-99.1875135502596, 19.297706970455657],
                    [-99.18732329986862, 19.297964954396743],
                    [-99.18708875707685, 19.298187651095105],
                    [-99.18681704834833, 19.29836829387171],
                    [-99.18651642952716, 19.298501393843832],
                    [-99.18619603497446, 19.298582906719304],
                    [-99.1858656, 19.29861035569436]
                ]
                
                ],
              },
            },
          ],
        },
      });

      this.map.addLayer({
        id: 'geocerca-layer',
        type: 'fill',
        source: 'geocerca',
        layout: {},
        paint: {
          'fill-color': '#ffd50e',
          'fill-opacity': 0.4,
        },
      });

      this.map.addLayer({
        id: 'geocerca-outline',
        type: 'line',
        source: 'geocerca',
        layout: {},
        paint: {
          'line-color': '#000000',
          'line-width': 2,
        },
      });
    });
  }

  /**
   * Método que regresa a la posición del pin del ATM en la vista del mapa
   * @author {ALM}
   */
  recenterMap() {
    if (this.currentMarker) 
      this.currentMarker.remove();
    
    this.currentMarker = new maplibregl.Marker().setLngLat([this._long, this._lat]).addTo(this.map);

    this.map.flyTo({
      center: [this._long, this._lat],
      essential: true,
    });
  }
}
