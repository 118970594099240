import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-route-bar',
  templateUrl: './route-bar.component.html',
  styleUrls: ['./route-bar.component.scss']
})
export class RouteBarComponent {
  paths: any = [];

  constructor(private router: Router, private route: ActivatedRoute) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) this.setPaths();
    });
    this.setPaths();
  }
  navigate(path:string) {
    let pathTo = this.paths.reduce((finalPath: string | any[], item: { path: string; }, index: number) => (this.paths.indexOf(path) >= index ? finalPath.concat("/" + item.path) : finalPath), "");
    this.router.navigate([decodeURI(pathTo)], { queryParams: this.route.snapshot.queryParams });
  }
  setPaths() {
    let url:string = this.router.url;
    this.paths = [];
    url
      .split("?")[0]
      .replace("/", "")
      .split("/")
      .forEach((item: string, index: any) => {
        let ch = item.charAt(0).toUpperCase();
        let text:any = decodeURI(textos[item] || item.replace(item[0], ch));
        this.paths.push({ text: text, path: item });
      });
  }
}
const textos:{ [key: string]: string } = {
  monitoreo: "Monitoreo",
  configuracion: "Configuración",
  reportes: "Reportes"
};
