import { Component, Input } from '@angular/core';
import { inputTime } from 'src/app/interfaces/form';

@Component({
  selector: 'app-chart-dashboard',
  templateUrl: './chart-dashboard.component.html',
  styleUrls: ['./chart-dashboard.component.scss']
})
export class ChartDashboardComponent {
  @Input() data!:any;
  @Input() equipmentId:string = '';
  @Input() variableId:string = '';
  indexDate: number = 15;
  timeOptions: inputTime [] = [
    { text: "15 minutos", value: 15 },
    { text: "30 minutos", value: 30 },
    { text: "1 hora", value: 60 },
    { text: "2 horas", value: 120 },
    { text: "6 horas", value: 360 },
    { text: "12 horas", value: 720 },
    { text: "1 día", value: 1440 },
    { text: "5 días", value: 7200 },
    { text: "10 días", value: 14400 },
    { text: "20 días", value: 28800 },
    { text: "1 mes", value: 43200 },
    { text: "Personalizado", value: -1}
  ];
}
