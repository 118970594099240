import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VariablesManagerService } from 'src/app/services/variables/variables-manager.service';

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss']
})
export class MonitoringComponent {
  // Variables para desarrollo 
  dataShops: any = [
    { shop_number: 542, name: "Tienda 1", address: "Insurgentes Sur 3579 - Tlalpan, La Joya CP 03100" },
    { shop_number: 2, name: "Tienda 2", address: "Insurgentes Sur 3579 - Tlalpan, La Joya CP 03100" },
    { shop_number: 3, name: "Tienda 3", address: "Insurgentes Sur 3579 - Tlalpan, La Joya CP 03100" },
    { shop_number: 3, name: "Tienda 3", address: "Insurgentes Sur 3579 - Tlalpan, La Joya CP 03100" },
    { shop_number: 3, name: "Tienda 3", address: "Insurgentes Sur 3579 - Tlalpan, La Joya CP 03100" },
    { shop_number: 3, name: "Tienda 3", address: "Insurgentes Sur 3579 - Tlalpan, La Joya CP 03100" }
  ]
  // Variables del componente
  queryParams: any;
  params: any;
  temperatureLimits: any = { min: 17, max: 27 };
/**TODO: Se debe crear una interfaz para los datos de la sucursal */
  branch: any = {
    name: "Tienda 1",
    description: "Este es un ejemplo",
    id:542,
    equipment: [
      { 
        parent: "tiendas:1708720744482",
        id: "equipos:1708720798439",
        description: "ATM que alberga el NEW_DEVICE",
        name: "ATM 1",
        type: "ATM",
        variables: [
          {
            "id": "1717707896123-e9b5",
            "alarms": {
              "min": {
                "N": "0"
              },
              "max": {
                "N": "1"
              },
              "maxAlarm": {
                "NULL": true
              },
              "minAlarm": {
                "BOOL": false
              },
              "emailsForNotifications": {
                "S": "iot@elektra.com.mx"
              },
              "unreachableNotificationsPeriod": {
                "N": "580"
              },
              "notificationsPeriod": {
                "N": "50"
              }
            },
            "description": "Variable para detectar la apertura de bandeja",
            "deviceId": "NEW_DEVICE_SAURON",
            "label": "Bandeja",
            "name": "Bandeja",
            "parent": "equipos:1708720798439",
            "protocol": "Sauron",
            "sauron": {
              "type": {
                "N": "34"
              }
            },
            "variable": "ESTADO_FUNCIONANDO"
          },
          {
            "id": "1716827787713-6d6d",
            "alarms": {
              "min": {
                "N": "0"
              },
              "max": {
                "N": "1"
              },
              "maxAlarm": {
                "NULL": true
              },
              "minAlarm": {
                "BOOL": true
              },
              "emailsForNotifications": {
                "S": "iot@elektra.com.mx"
              },
              "unreachableNotificationsPeriod": {
                "N": "25"
              },
              "notificationsPeriod": {
                "N": "50"
              }
            },
            "description": "Verifica la comunicación del cajero",
            "deviceId": "NEW_DEVICE_SAURON",
            "label": "Connection_status",
            "name": "Connection_status",
            "parent": "equipos:1708720798439",
            "protocol": "Sauron",
            "sauron": {
              "type": {
                "N": "33"
              }
            },
            "variable": "ESTADO_FUNCIONANDO"
          },
          {
            "id": "1716835079425-4a97",
            "alarms": {
              "min": {
                "N": "0"
              },
              "max": {
                "N": "1"
              },
              "maxAlarm": {
                "NULL": true
              },
              "minAlarm": {
                "NULL": true
              },
              "emailsForNotifications": {
                "S": "iot@elektra.com.mx"
              },
              "unreachableNotificationsPeriod": {
                "N": "580"
              },
              "notificationsPeriod": {
                "N": "50"
              }
            },
            "description": "Variable para la Geolocalizacion del ATM - Longitud",
            "deviceId": "NEW_DEVICE_SAURON",
            "label": "Longitud ATM",
            "name": "Longitud ATM",
            "parent": "equipos:1708720798439",
            "protocol": "Sauron",
            "sauron": {
              "type": {
                "N": "26"
              }
            },
            "variable": "ESTADO_FUNCIONANDO"
          },
          {
            "id": "1717687044746-2865",
            "alarms": {
              "min": {
                "N": "0"
              },
              "max": {
                "N": "1"
              },
              "maxAlarm": {
                "NULL": true
              },
              "minAlarm": {
                "BOOL": false
              },
              "emailsForNotifications": {
                "S": "iot@elektra.com.mx"
              },
              "unreachableNotificationsPeriod": {
                "N": "580"
              },
              "notificationsPeriod": {
                "N": "50"
              }
            },
            "description": "Variable para detectar la apertura de puerta boveda",
            "deviceId": "NEW_DEVICE_SAURON",
            "label": "Boveda",
            "name": "Boveda",
            "parent": "equipos:1708720798439",
            "protocol": "Sauron",
            "sauron": {
              "type": {
                "N": "35"
              }
            },
            "variable": "ESTADO_FUNCIONANDO"
          },
          {
            "id": "1716834815090-8b73",
            "alarms": {
              "min": {
                "N": "0"
              },
              "max": {
                "N": "1"
              },
              "maxAlarm": {
                "NULL": true
              },
              "minAlarm": {
                "NULL": true
              },
              "emailsForNotifications": {
                "S": "iot@elektra.com.mx"
              },
              "unreachableNotificationsPeriod": {
                "N": "580"
              },
              "notificationsPeriod": {
                "N": "50"
              }
            },
            "description": "Variable para la Geolocalizacion INICIAL del ATM - Longitud",
            "deviceId": "NEW_DEVICE_SAURON",
            "label": "Longitud ATM",
            "name": "init_longitud",
            "parent": "equipos:1708720798439",
            "protocol": "Sauron",
            "sauron": {
              "type": {
                "N": "29"
              }
            },
            "variable": "ESTADO_FUNCIONANDO"
          },
          {
            "id": "1716835130037-0281",
            "alarms": {
              "min": {
                "N": "0"
              },
              "max": {
                "N": "1"
              },
              "maxAlarm": {
                "NULL": true
              },
              "minAlarm": {
                "NULL": true
              },
              "emailsForNotifications": {
                "S": "iot@elektra.com.mx"
              },
              "unreachableNotificationsPeriod": {
                "N": "580"
              },
              "notificationsPeriod": {
                "N": "50"
              }
            },
            "description": "Variable para la Geolocalizacion INICIAL del ATM - Latitud",
            "deviceId": "NEW_DEVICE_SAURON",
            "label": "Latitud ATM",
            "name": "init_latitud",
            "parent": "equipos:1708720798439",
            "protocol": "Sauron",
            "sauron": {
              "type": {
                "N": "28"
              }
            },
            "variable": "ESTADO_FUNCIONANDO"
          },
          {
            "id": "1717686939202-29e6",
            "alarms": {
              "min": {
                "N": "0"
              },
              "max": {
                "N": "1"
              },
              "maxAlarm": {
                "NULL": true
              },
              "minAlarm": {
                "BOOL": false
              },
              "emailsForNotifications": {
                "S": "iot@elektra.com.mx"
              },
              "unreachableNotificationsPeriod": {
                "N": "580"
              },
              "notificationsPeriod": {
                "N": "50"
              }
            },
            "description": "Variable para detectar la apertura de puerta principal",
            "deviceId": "NEW_DEVICE_SAURON",
            "label": "Puerta principal",
            "name": "Puerta principal",
            "parent": "equipos:1708720798439",
            "protocol": "Sauron",
            "sauron": {
              "type": {
                "N": "36"
              }
            },
            "variable": "ESTADO_FUNCIONANDO"
          },
          {
            "id": "1716835225897-8937",
            "alarms": {
              "min": {
                "N": "25"
              },
              "max": {
                "N": "300"
              },
              "maxAlarm": {
                "BOOL": false
              },
              "minAlarm": {
                "NULL": true
              },
              "emailsForNotifications": {
                "S": "iot@elektra.com.mx"
              },
              "unreachableNotificationsPeriod": {
                "N": "580"
              },
              "notificationsPeriod": {
                "N": "50"
              }
            },
            "description": "Variable para detectar presencia en cm",
            "deviceId": "NEW_DEVICE_SAURON",
            "label": "Presencia_cm",
            "name": "Presencia_cm",
            "parent": "equipos:1708720798439",
            "protocol": "Sauron",
            "sauron": {
              "type": {
                "N": "30"
              }
            },
            "variable": "NUMERO_ENTERO"
          },
          {
            "id": "1716834775089-6257",
            "alarms": {
              "min": {
                "N": "0"
              },
              "max": {
                "N": "1"
              },
              "maxAlarm": {
                "NULL": true
              },
              "minAlarm": {
                "NULL": true
              },
              "emailsForNotifications": {
                "S": "iot@elektra.com.mx"
              },
              "unreachableNotificationsPeriod": {
                "N": "580"
              },
              "notificationsPeriod": {
                "N": "50"
              }
            },
            "description": "Variable para la Geolocalizacion del ATM - Latitud",
            "deviceId": "NEW_DEVICE_SAURON",
            "label": "Latitud ATM",
            "name": "Latitud ATM",
            "parent": "equipos:1708720798439",
            "protocol": "Sauron",
            "sauron": {
              "type": {
                "N": "25"
              }
            },
            "variable": "ESTADO_FUNCIONANDO"
          },
          {
            "id": "1716834731936-19c0",
            "alarms": {
              "min": {
                "N": "9"
              },
              "max": {
                "N": "14"
              },
              "maxAlarm": {
                "BOOL": true
              },
              "minAlarm": {
                "BOOL": true
              },
              "emailsForNotifications": {
                "S": "iot@elektra.com.mx"
              },
              "unreachableNotificationsPeriod": {
                "N": "580"
              },
              "notificationsPeriod": {
                "N": "3600"
              }
            },
            "description": "Variable para medicion de Voltaje en DC",
            "deviceId": "NEW_DEVICE_SAURON",
            "label": "Voltage_DC",
            "name": "Voltage_DC",
            "parent": "equipos:1708720798439",
            "protocol": "Sauron",
            "sauron": {
              "type": {
                "N": "27"
              }
            },
            "variable": "ESTADO_FUNCIONANDO"
          }
        ]
      },
      { 
        parent: "tiendas:1708720744482",
        id: "equipos:1717624823201",
        description: "ATM que alberga el NEW_DEVICE",
        name: "ATM 2",
        type: "ATM",
        variables: [
          {
            "id": "1717638081430-574f",
            "alarms": {
             "emailsForNotifications": "iot@elektra.com.mx",
             "max": 1,
             "maxAlarm": null,
             "min": 0,
             "minAlarm": null,
             "notificationsPeriod": 50,
             "unreachableNotificationsPeriod": 580
            },
            "description": "Variable para la Geolocalizacion del ATM - Latitud",
            "deviceId": "NEW_DEVICE_SAURON_1",
            "label": "Latitud  y Longitud ATM",
            "name": "Latitud_y_Longitud",
            "parent": "equipos:1717624823201",
            "protocol": "Sauron",
            "sauron": {
             "type": 37
            },
            "variable": "NA"
           },
          {
            id: "1713480486280-a292",
            alarms: {
             emailsForNotifications: "iot@elektra.com.mx",
             max: 1,
             maxAlarm: null,
             min: 0,
             minAlarm: null,
             notificationsPeriod: 50,
             unreachableNotificationsPeriod: 580
            },
            bot: {
             type: 10
            },
            description: "Variable para medicion de Voltaje en DC",
            deviceId: "NEW_DEVICE_SAURON_1",
            i2c: {
             address: 111,
             channel: 1,
             channels: 1,
             type: 2
            },
            label: "Voltage_DC",
            modbus: {
             address: "0",
             bits: 0,
             divisor: 0,
             endian: "0",
             ip: "0.0.0.0",
             offset: 0,
             slave: "0",
             type: 20,
             zero_is_unreachable: null
            },
            name: "Voltage_DC",
            parent: "equipos:1708720798439",
            protocol: "Sauron",
            sauron: {
             type: 27
            },
            variable: "ESTADO_FUNCIONANDO"
          },
          {
            id: "1708743705046-72dc",
            alarms: {
             emailsForNotifications: "iot@elektra.com.mx",
             max: 1,
             maxAlarm: null,
             min: 0,
             minAlarm: null,
             notificationsPeriod: 50,
             unreachableNotificationsPeriod: 580
            },
            description: "Variable para la Geolocalizacion del ATM - Latitud",
            deviceId: "NEW_DEVICE_SAURON_1",
            label: "Latitud ATM",
            modbus: {
             address: "0",
             bits: 0,
             divisor: 0,
             endian: "0",
             ip: "0.0.0.0",
             offset: 0,
             slave: "0",
             type: 24,
             zero_is_unreachable: null
            },
            name: "Latitud ATM",
            parent: "equipos:1708720798439",
            protocol: "Sauron",
            sauron: {
             type: 25
            },
            variable: "ESTADO_FUNCIONANDO"
          },
          {
            id: "1710983211222-02a7",
            alarms: {
             emailsForNotifications: "iot@elektra.com.mx",
             max: 1,
             maxAlarm: null,
             min: 0,
             minAlarm: null,
             notificationsPeriod: 50,
             unreachableNotificationsPeriod: 580
            },
            description: "Variable para la Geolocalizacion del ATM - Longitud",
            deviceId: "NEW_DEVICE_SAURON_1",
            label: "Longitud ATM",
            modbus: {
             address: "0",
             bits: 0,
             divisor: 0,
             endian: "0",
             ip: "0.0.0.0",
             offset: 0,
             slave: "0",
             type: 24,
             zero_is_unreachable: null
            },
            name: "Longitud ATM",
            parent: "equipos:1708720798439",
            protocol: "Sauron",
            sauron: {
             type: 26
            },
            variable: "ESTADO_FUNCIONANDO"
          },
          {
            id: "1715618408797-e245",
            alarms: {
             emailsForNotifications: "iot@elektra.com.mx",
             max: 300,
             maxAlarm: false,
             min: 25,
             minAlarm: null,
             notificationsPeriod: 50,
             unreachableNotificationsPeriod: 580
            },
            description: "Variable para detectar presencia en cm",
            deviceId: "NEW_DEVICE_SAURON_1",
            label: "Presencia_cm",
            name: "Presencia_cm",
            parent: "equipos:1708720798439",
            protocol: "Sauron",
            sauron: {
             type: 30
            },
            variable: "NUMERO_ENTERO"
           }
        ]
      },
      {
        parent: "tiendas:1708720744482",
        id: "equipos:1717525004350",
        description: '',
        name: "ATM D",
        type: "ATM",
        variables: [
          {
          "id": "1717688125713-1eed",
          "alarms": {
           "emailsForNotifications": null,
           "max": null,
           "maxAlarm": null,
           "min": null,
           "minAlarm": null,
           "notificationsPeriod": null,
           "unreachableNotificationsPeriod": null
          },
          "description": "Latitud_y_Longitud",
          "deviceId": "1715812565-09f8",
          "electric_line": null,
          "label": "Latitud_y_Longitud",
          "name": "Latitud_y_Longitud",
          "onlySendAlarms": null,
          "parent": "equipos:1717525004350",
          "protocol": "Sauron",
          "sauron": {
           "type": 37
          },
          "states": null,
          "variable": "NA",
          "lastData": {
            "value": "19.29972127817736&-99.18665650519797",
            "date": String(new Date())
        }
         },
          {
            "id": "1717543636749-2837",
            "alarms": {
              "emailsForNotifications": null,
              "max": null,
              "maxAlarm": null,
              "min": null,
              "minAlarm": null,
              "notificationsPeriod": null,
              "unreachableNotificationsPeriod": null
            },
            "deviceId": "1715812565-09f8",
            "label": "test",
            "name": "Longitud ATM",
            "parent": "equipos:1717525004350",
            "protocol": "Sauron",
            "sauron": {
              "type": 29
            },
            variable: "ESTADO_FUNCIONANDO"
          },
          {
            "id": "1717543657726-ce6e",
            "alarms": {
              "emailsForNotifications": null,
              "max": null,
              "maxAlarm": null,
              "min": null,
              "minAlarm": null,
              "notificationsPeriod": null,
              "unreachableNotificationsPeriod": null
            },
            "deviceId": "1715812565-09f8",
            "label": "test",
            "name": "Latitud ATM",
            "parent": "equipos:1717525004350",
            "protocol": "Sauron",
            "sauron": {
              "type": 28
            },
            variable: "ESTADO_FUNCIONANDO"
          },
          {
            "id": "1717543751765-af95",
            "alarms": {
              "emailsForNotifications": null,
              "max": null,
              "maxAlarm": null,
              "min": null,
              "minAlarm": null,
              "notificationsPeriod": null,
              "unreachableNotificationsPeriod": null
            },
            "deviceId": "1715812565-09f8",
            "label": "test",
            "name": "Cajero Operando",
            "parent": "equipos:1717525004350",
            "protocol": "Sauron",
            "sauron": {
              "type": 33
            },
            variable: "ESTADO_FUNCIONANDO"
          },
          {
            "id": "1717543824112-2a96",
            "alarms": {
              "emailsForNotifications": null,
              "max": null,
              "maxAlarm": null,
              "min": null,
              "minAlarm": null,
              "notificationsPeriod": null,
              "unreachableNotificationsPeriod": null
            },
            "deviceId": "1715812565-09f8",
            "label": "test",
            "name": "Bandeja",
            "parent": "equipos:1717525004350",
            "protocol": "Sauron",
            "sauron": {
              "type": 33
            },
            variable: "ESTADO_FUNCIONANDO"
          },
          {
            "id": "1717543816175-911c",
            "alarms": {
              "emailsForNotifications": null,
              "max": null,
              "maxAlarm": null,
              "min": null,
              "minAlarm": null,
              "notificationsPeriod": null,
              "unreachableNotificationsPeriod": null
            },
            "deviceId": "1715812565-09f8",
            "label": "test",
            "name": "Boveda",
            "parent": "equipos:1717525004350",
            "protocol": "Sauron",
            "sauron": {
              "type": 33
            },
            variable: "ESTADO_FUNCIONANDO"
          },
          {
            "id": "1717543719830-464d",
            "alarms": {
              "emailsForNotifications": null,
              "max": null,
              "maxAlarm": null,
              "min": null,
              "minAlarm": null,
              "notificationsPeriod": null,
              "unreachableNotificationsPeriod": null
            },
            "deviceId": "1715812565-09f8",
            "label": "test",
            "name": "Voltage_DC",
            "parent": "equipos:1717525004350",
            "protocol": "Sauron",
            "sauron": {
              "type": 27
            },
            variable: "ESTADO_FUNCIONANDO"
          },
          {
            "id": "1717543681191-a73a",
            "alarms": {
              "emailsForNotifications": null,
              "max": null,
              "maxAlarm": null,
              "min": null,
              "minAlarm": null,
              "notificationsPeriod": null,
              "unreachableNotificationsPeriod": null
            },
            "deviceId": "1715812565-09f8",
            "label": "Radar",
            "name": "Presencia_cm",
            "parent": "equipos:1717525004350",
            "protocol": "Sauron",
            "sauron": {
              "type": 30
            },
            variable: "NUMERO_ENTERO"
          },
          {
            "id": "1717543782654-3d13",
            "alarms": {
              "emailsForNotifications": null,
              "max": null,
              "maxAlarm": null,
              "min": null,
              "minAlarm": null,
              "notificationsPeriod": null,
              "unreachableNotificationsPeriod": null
            },
            "deviceId": "1715812565-09f8",
            "label": "test",
            "name": "Puerta principal",
            "parent": "equipos:1717525004350",
            "protocol": "Sauron",
            "sauron": {
              "type": 33
            },
            variable: "ESTADO_FUNCIONANDO"
          }
        ]
      }
    ]
  };
  variables: any[] = [];
  variablesService!: VariablesManagerService;

  loading: boolean = true;

  constructor(private router: Router, private route: ActivatedRoute, private cdk: ChangeDetectorRef) {
    // route.queryParams.subscribe(params => (this.queryParams = params));
    // route.params.subscribe(params => (this.params = params));
  }
  async ngOnInit() {
    /** Ejemplo par integracion de backend, fundamental para hace peticiones. NO BORRAR
      const authToken = (await Auth.fetchAuthSession()).tokens?.idToken?.toString()!;
      const restOperation = (await API.get({ apiName: "ATMApi", path: "/monitoring/", options: { headers: { Authorization: authToken } } }).response).body
      const res = await restOperation;
    */
   
      this.setData();
  }

  setData(){
    this.getAllVariables();
    this.variablesService = new VariablesManagerService(this.variables);
    this.variablesService.variablesSubscription((response: any) => {
      for(let i=0;i<this.branch.equipment.length;i++)
        for(let j=0;j<this.branch.equipment[i].variables.length;j++)
          this.branch.equipment[i].variables[j]['lastData'] = this.variablesService.variables.find(variable => variable.id == this.branch.equipment[i].variables[j].id)?.lastData;
      // this.variablesService.checkData();
      this.cdk.markForCheck();
    });
  }

  getAllVariables(){
    this.branch.equipment.forEach((atm: any) => this.variables = this.variables.concat(atm.variables));
  }
}
