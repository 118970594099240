import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { VariablesService } from 'src/app/services/variables/variables.service';

@Component({
  selector: 'app-pde-general-control',
  templateUrl: './pde-general-control.component.html',
  styleUrls: ['./pde-general-control.component.scss']
})
export class PdeGeneralControlComponent {
  isChecked: boolean = false;

  VinVout: boolean = false;
  Corrientes: boolean = false;
  Potencias: boolean = false;
  circle: boolean = true;
  showCase: boolean = false;

    @Input() set variables(variables: VariablesService[]) {
      if(variables){
        this.okGenerator = variables.find(variable => variable.name == "PDE - Load On Generator Source")
        this.frequency = variables.find(variable => variable.name == "PDE - Generator frequency")
        this.voltageL1 = variables.find(variable => variable.name == "PDE - Generator L1-N voltage")
        this.voltageL2 = variables.find(variable => variable.name == "PDE - Generator L2-N voltage")
        this.voltageL3 = variables.find(variable => variable.name == "PDE - Generator L3-N voltage")
        this.cfe = variables.find(variable => variable.name == "PDE - Mains L1-N voltage ")
        this.rpm = variables.find(variable => variable.name == "PDE - Engine Speed (RPM)")
        this.voltageBattery = variables.find(variable => variable.name == "PDE - Engine Battery Voltage")
        this.temp = variables.find(variable => variable.name == "PDE - Coolant Temperature")

        this.sensorSelected = this.frequency
       
        // this.mVinVout = this.variables.find(variable => variable.name == "VinVout");
        // this.mCorrientes = this.variables.find(variable => variable.name == "Corrientes");
        // this.mPotencias = this.variables.find(variable => variable.name == "Potencias");

      }
    }

  dummyInfoArray = [
    { "left": "Frecuencia del generador L1", "right": "60 Hz" },
    { "left": "Voltaje tarjeta de Arranque y Paro", "right": "26 V" },
    { "left": "Horas de Trabajo", "right": "26 V" },
    { "left": "Voltaje Controlador", "right": "0 hrs" },
  ];

  selectedModeBtn: string | null = 'Aut';
  selectedModeBtnOpc: string ='';

  form: FormGroup;
  okGenerator: any;
  mVinVout: any;
  mCorrientes: any;
  mPotencias: any;


  frequency: any;
  voltageL1: any;
  voltageL2: any;
  voltageL3: any;
  cfe: any;
  rpm: any;
  voltageBattery: any;
  temp: any;

  sensorSelected: any;
  auxDate = new Date();


  constructor(private fb: FormBuilder) {
    this.auxDate.setMinutes(this.auxDate.getMinutes() - 1)
    this.form = this.fb.group({
      selectedButton: [null]
    });
  }

  selectModeButton(button: string) {
    this.selectedModeBtn = button;
  }

   /**
   * 
   * @param sensor string
   * Función  para seleccionar la grafica que se mostrara en el dashboard
   */
   procesarOpcion(sensor: string) {
    switch (sensor) {
      case 'Vin/Vout':
        this.VinVout = true;
        this.Corrientes = false;
        this.Potencias = false;
        this.circle = false;
        this.showCase = false;
        this.sensorSelected = this.voltageL1
        break;
      case 'Corrientes':
        this.VinVout = false;
        this.Corrientes = true;
        this.Potencias = false;
        this.circle = false;
        this.showCase = false;
        break;
      case 'Potencias':
        this.VinVout = false;
        this.Corrientes = false;
        this.Potencias = true;
        this.circle = false;
        this.showCase = false;
        break;
      case 'circle':
        this.VinVout = false;
        this.Corrientes = false;
        this.Potencias = false;
        this.circle = true;
        this.showCase = false;
        this.sensorSelected = this.frequency
        break;
    }
    this.selectedModeBtnOpc = sensor;
  }

}
