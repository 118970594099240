<div class="widget-main-container h1 relative" [class.hidden]="hidden">
    <div [ngClass]="['ekt-loader', !loading ? 'hide' : '']">
      <div></div>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="header">
      <div class="title"> <span class="pointer"  queryParamsHandling="preserve"><b>{{ name }}</b></span>
      </div>
    </div>
    <div class="content">
      <div class="left">
        <div>
          <div class="charts-container">
            <app-donut-chart *ngFor="let variable of _donutVariables" [data]="variable.dataToShow"></app-donut-chart>
          </div>
          <div *ngIf="_remainingTime" class="left-text" [title]="_remainingTime.sensor?.lastData?.dateString || ''">
            Tiempo restante:&nbsp;<b>{{ _remainingTime.sensor?.lastData?.value || "-" }} min.</b>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="vars-container">
            <!-- <span>{{ variable.name }}</span>
            <span> {{ variable?.sensor?.lastData?.value >= 0 ? variable.sensor.lastData.value : "-" }}{{ variable?.sensor?.units && variable.sensor.units != "N/A" ? variable.sensor.units : "" }}</span> -->
            <span><b>Usando Energía de:</b></span>
            <br>
            <br>
            <span>CFE</span>
  
        </div>
      </div>
    </div>
  </div>
  