import { VariablesService } from "./variables.service";

export class GpsService {
    public status: any;
    public sLatutud: VariablesService | undefined;
    public sLongitud: VariablesService | undefined;

    constructor(sLatitude: VariablesService | undefined, sLongitud: VariablesService | undefined) {
        this.sLatutud = sLatitude;
        this.sLongitud = sLongitud;
    }

    checkStatus(){
        this.sLatutud?.checkData();
        this.sLongitud?.checkData();

        let distance = this.getDistance();
        
        if(distance <= 5 && (this.sLatutud?.status == "ok" && this.sLongitud?.status == "ok")) this.status = "ok";
        else this.status = "error";

        // console.log("GPS", this)
    }

    getDistance(){
        const lat1 = 19.2967752, lon1 = -99.1858672;
        const lat2 = 19.2968193, lon2 = -99.1858672;

        const R = 6371;
        const rad = Math.PI / 180;
        
        const dlat = (lat2 - lat1) * rad;
        const dlon = (lon2 - lon1) * rad;
        
        const a = Math.sin(dlat / 2) ** 2 + Math.cos(lat1 * rad) * Math.cos(lat2 * rad) * Math.sin(dlon / 2) ** 2;
        const c = 2 * Math.asin(Math.sqrt(a));

        return Number((R * c * 1000).toFixed(2));
    }
}
