<app-mask [mask]="mask"></app-mask>
<mat-card-actions class="card-select-component">
  <button mat-button [class.selected-mode-btn]="selectedComponent === 'atm'" (click)="selectComponent('atm')">  
    <img class="button-icon" src="../assets/images/atm_icon.svg"  alt="Aut Icon">
  </button>
  <button mat-button [class.selected-mode-btn]="selectedComponent === 'pde'" (click)="selectComponent('pde')">
    <img class="button-icon" src="../assets/images/pde_icon.svg"  alt="Aut Icon">
  </button>
</mat-card-actions>
<div class="general-status-items">
  <div class="pde_container" *ngIf="selectedComponent === 'pde'">
    <app-pde-general-control 
      *ngFor="let pde of pdeDevices" 
      [variables]="pde.sensors">
    </app-pde-general-control>
  </div>

  <div class="atm_container" *ngIf="selectedComponent === 'atm'">
    <!--       
      [sGpsStatus]="variablesService.gps.status"
      [variables]="atm.sensors"
      [equipmentInfo]="atm"
    -->
    <atm-general-status [atms]="atmDevices"></atm-general-status>
  </div>
  
</div>