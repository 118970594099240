<div class="main-container" [title]="_dateToShow || ''">
    <div style="position: absolute;">
      <canvas #canvas></canvas>
    </div>
    <div style="position:absolute;">
      <div [ngClass]="['value', changing ? 'changing' : '']">
        {{ _valueToShow >= 0 ? _valueToShow : "-" }}<span style="font-size: 55%;">{{ _unitsToShow || "" }}</span>
      </div>
      <div class="value-name">{{ _data?.name }}</div>
    </div>
  </div>