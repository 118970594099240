import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import * as Auth from "@aws-amplify/auth";


export const appGuard: CanActivateFn = async (route, state) => {
  let router = inject(Router);
  try {
    let user: any = await getSessionCurrent();
    if (user.username) {
      return true
    }
    return false
  } catch (error) {
    router.navigate(["/login"])
    return false;
  }
};

async function getSessionCurrent() {
  return new Promise(async (resolve, reject) => {
    try {
      let user = await Auth.getCurrentUser();
      resolve(user)
    } catch (error) {
      reject("errorInSingIn")
    }
  })
}