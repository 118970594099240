import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { VariablesService } from 'src/app/services/variables/variables.service';
import { sensorTitle } from 'src/app/interfaces/variable';
import { AlarmsService } from 'src/app/services/mqtt/alarms.service';
import * as API from 'aws-amplify/api';
import * as Auth from 'aws-amplify/auth';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'atm-general-status',
  templateUrl: './general-status.component.html',
  styleUrls: ['./general-status.component.scss'],
})
export class GeneralStatusComponent implements OnInit,OnDestroy {
  @Input() set sBateryStatus(status: string | any) {
    this._sBateryStatus = status; 
  }
  @Input() set sGpsStatus(status: string | any) { 
    this._sGpsStatus = status; 
  }
  @Input() set variables(variables: VariablesService[]) {
    this._variables = variables;
    if(this._variables){
      this.sensorRadar = this._variables.find(variable => variable.name == "Presencia_cm");
      this.sensorLat = this._variables.find(variable => variable.name == "Latitud ATM");
      this.sensorLong = this._variables.find(variable => variable.name == "Longitud ATM");
      this.sensorBattery = this._variables.find(variable => variable.name == "Voltage_DC");
      this.sensorNet = this._variables.find(variable => variable.name == "Connection_status");
      this.sensorVault = this._variables.find(variable => variable.name == "Boveda");
      this.sensorTray = this._variables.find(variable => variable.name == "Bandeja");
      this.sensorGeo = this._variables.find(variable => variable.name == "Latitud_y_Longitud");
      this.sensorFakeDoor = this._variables.find(variable => variable.name == "Puerta principal");
    }
  }
  /** TODO: Se debe crear una interfaz para los datos de la sucursal */
  @Input() set equipmentInfo(branch:any){
    this._equipmentInfo = branch;
    this.equipmentId = branch.id;
    this.atmName = branch.name;
  };
  @Input() loading: boolean = true;
  @Input() hidden: boolean = false;
  @Input() name: string = '';
  @Input() active: boolean = false;
  @Input() set mainSource(mainSource: any) {
    if (!mainSource) return;
    this.ngOnInit();
  }
  @Input() set atm(atm: any) {
    this.ngOnInit();
  }
  _mainSource: any;
  _atm: any;

  _atsOutputStatus: string = '';
  _commercialStatus: string = '';
  _pdeStatus: string = '';
  _upsOutputSatatus: string = '';

  _atsSource: string = '';

  _mqttSubscription: any;

  _sBateryStatus: string | any;
  _sGpsStatus: string | any;
  _variables!: VariablesService[];

  sensorRadar!: VariablesService | any;
  sensorGeo!: VariablesService | any;
  sensorLat!: VariablesService | any;
  sensorLong!: VariablesService | any;
  sensorBattery!: VariablesService | any;
  sensorNet!: VariablesService | any;
  sensorVault!: VariablesService | any;
  sensorTray!: VariablesService | any;
  sensorFakeDoor!:  VariablesService | any;

  mqttSubscription: any;
  sensorId = '1707532412-a839';
  bandGreen: boolean = false;
  sensors:sensorTitle = {
    geo: 'GEOLOCALIZACIÓN',
    battery: 'CAJERO ENERGIZADO',
    net:'CAJERO OPERANDO',
    cover: 'PUERTA FALSA',
    gate: 'BÓVEDA',
    cpuCover: 'BANDEJA'
  }
  
  title: string = '';
  atmName: string = 'ATM';

  showMap: boolean = true;
  showBatery: boolean = false;
  showNet: boolean = false;
  showDoor:boolean = false;
  showGate:boolean = false;
  showCase:boolean = false;
  showFakeDoor:boolean = false;
  _equipmentInfo:any;
  equipmentId!:string;
  interval!:any;
  dataPoints!: {value: number, date: Date};
  isAlarma:boolean = false;
  constructor(private alarms: AlarmsService,private router:ActivatedRoute) { }

  async ngOnInit(): Promise<void> {
    console.log(this.sensorBattery);
    const navigate = this.router.snapshot.paramMap.get('status');
    const lastD = this.router.snapshot.paramMap.get('lastD');
    if(navigate&&this._equipmentInfo.id == 'equipos:1717624823201'){
      this.sensorGeo.lastData = {value:lastD,date:new Date()};
      this.isAlarma = true;
    }
    this.title= this.sensors.geo;
    if (this._mainSource && this._atm) {
      this.setStatus();
    }
    this.createData();
    this.alarms.mqttSubscription((result:any)=>{
      if(result){
        clearInterval(this.interval)
  
      this.setInterval(async ()=>{
        if(this._equipmentInfo.id == 'equipos:1717525004350')
          this.isAlarma = this.sensorGeo?.lastData?.value.split('&')[1] == '-99.1875468902426' || this.sensorGeo?.lastData?.value.split('&')[1] == '-99.18850142659687' 
            
        
        const authToken = (await Auth.fetchAuthSession()).tokens?.idToken?.toString()!;
        const restOperation = (await API.get({ apiName: "ATMApi", path: `/monitoring/getLatestLog/${result.S}`, options: { headers: { Authorization: authToken } } }).response).body
        restOperation.json().then((territories: any) => {
          // console.log(territories.message.detail);
          // /**Alarma de la geocerca */
          if(territories.message.detail.EventType){
            let status = territories.message.detail.EventType;
            if(this._equipmentInfo.id == 'equipos:1717624823201')
              if(status == "EXIT") this.isAlarma = true;
              else this.isAlarma = false;
          }
        }).catch(r => console.log(r))
      })
      }
      
    })
  }
  ngOnDestroy() {
    if (this._mqttSubscription) this._mqttSubscription.unsubscribe();
  }
  setInterval(fn:any){
    this.interval = setInterval(fn,500);
  }
  setStatus() {
    this._commercialStatus = [
      'inputPhaseA',
      'inputPhaseB',
      'inputPhaseC',
      'frecuency',
    ].reduce(
      (isActive, variableKey) =>
        isActive &&
        this._mainSource.variables &&
        this._mainSource.variables[variableKey] &&
        this._mainSource.variables[variableKey].sensor &&
        this._mainSource.variables[variableKey].sensor.lastData &&
        this._mainSource.variables[variableKey].sensor.lastData.value > 0,
      true
    )
      ? 'active'
      : '';
    if (this._atm)
      this._pdeStatus = [
        'outputPhaseA',
        'outputPhaseB',
        'outputPhaseC',
        'frecuency',
      ].reduce(
        (isActive, variableKey) =>
          isActive &&
          this._atm.variables &&
          this._atm.variables[variableKey] &&
          this._atm.variables[variableKey].sensor &&
          this._atm.variables[variableKey].sensor.lastData &&
          this._atm.variables[variableKey].sensor.lastData.value > 0,
        true
      )
        ? 'active'
        : '';
  }

  cloneVariable(variable: any) {
    let newVariable = Object.assign({}, variable);
    if (newVariable.sensor) {
      newVariable.sensor = Object.assign({}, newVariable.sensor);
      if (newVariable.sensor.lastData)
        newVariable.sensor.lastData = Object.assign(
          {},
          newVariable.sensor.lastData
        );
    }
    return newVariable;
  }
  /**
   * 
   * @param sensor string
   * Función  para seleccionar la grafica que se mostrara en el dashboard
   */
  handleSensorClicked(sensor: string) {
    switch (sensor) {
      case 'geolocalization':
        this.showMap = true;
        this.showBatery = false;
        this.showNet = false;
        this.showDoor = false;
        this.showGate = false;
        this.showCase = false;
        this.showFakeDoor = false;
        this.title = this.sensors.geo;
        break;
      case 'batery':
        this.title = this.sensors.battery;
        this.showMap = false;
        this.showBatery = true;
        this.showNet = false;
        this.showDoor = false;
        this.showGate = false;
        this.showCase = false;
        this.showFakeDoor = false;
        break;
      case 'atmNet':
        this.showMap = false;
        this.showBatery = false;
        this.showNet = true;
        this.showDoor = false;
        this.showGate = false;
        this.showCase = false;
        this.title = this.sensors.net;
        this.showFakeDoor = false;
        break;
        case 'atmDoor':
        this.showMap = false;
        this.showBatery = false;
        this.showNet = false;
        this.showDoor = true;
        this.showGate = false;
        this.showCase = false;
        this.showFakeDoor = false;
        this.title = this.sensors.cover;
        break;
        case 'atmGate':
        this.showMap = false;
        this.showBatery = false;
        this.showNet = false;
        this.showDoor = false;
        this.showGate = true;
        this.showCase = false;
        this.showFakeDoor = false;
        this.title = this.sensors.gate;
        break;
        case 'atmCase':
        this.showMap = false;
        this.showBatery = false;
        this.showNet = false;
        this.showDoor = false;
        this.showGate = false;
        this.showCase = true;
        this.showFakeDoor = false;
        this.title = this.sensors.cpuCover;
        break;
        case 'atmFakeDoor':
        this.showMap = false;
        this.showBatery = false;
        this.showNet = false;
        this.showDoor = false;
        this.showGate = false;
        this.showCase = false;
        this.showFakeDoor = true;
        this.title = this.sensors.cover;
        break;
      default:
        this.showMap = true;
        this.showBatery = false;
        this.showNet = false;
        this.showDoor = false;
        this.showGate = false;
        this.showCase = false;
        this.showFakeDoor = false;
        this.title = this.sensors.geo;
        break;
    }
  }

  createData(){
    setInterval( () => {
      this.dataPoints = {value: Math.floor(Math.random() * 2), date: new Date()}
    }, 60000)
  }

}