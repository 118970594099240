import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ServiceAmplifyService } from 'src/app/services/amplify/service-amplify.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  mediaMobile: MediaQueryList | undefined;
  private mobileQueryListener: () => {} | any;

  userEmail: string = '';
  userPassword: string = '';
  userLogged: boolean = false;
value: any;

  constructor(private amplifyService: ServiceAmplifyService, private media: MediaMatcher, private chengeDetectorRef: ChangeDetectorRef, private _snackBar: MatSnackBar, private router: Router){
    this.mediaMobile = media.matchMedia('(max-width: 800px)');
    this.mobileQueryListener = () => this.chengeDetectorRef.detectChanges();
    this.mediaMobile.addEventListener("change", this.mobileQueryListener);
    this.amplifyService.currentSessionUser().then(response => {
      if(response.userId) router.navigate(["/map-dashboard"])
    })
  }


  ngOnInit() {

  }

  async signIn() {
    try{
      let user = await this.amplifyService.signIn({username: this.userEmail, password: this.userPassword})
      this.userLogged = true;
      this.openSnackBar( { text: "Has iniciado sesión exitosamente.", action: "Aceptar" }, 2000 )
      this.router.navigate(["/monitoring"])
      this.amplifyService.isLogged = true;
    }catch(e){
      this.openSnackBar( { text: "Parece que ha habido un problema al iniciar sesión.", action: "Aceptar" } )
    }
  }

  openSnackBar(message: any, duration: number = 2000) {
    let classEvent = this.userLogged ? 'snack-bar' : 'snack-bar-error';
    const snackBarRef = this._snackBar.open(message.text, message.action, { panelClass: [classEvent] });

    setTimeout(() => {
      snackBarRef.dismiss();
    }, duration);
  }
  
}
