import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { VariablesService } from 'src/app/services/variables/variables.service';
import { sensorTitle, variable } from 'src/app/interfaces/variable';
import { AlarmsService } from 'src/app/services/mqtt/alarms.service';
import { ActivatedRoute } from '@angular/router';
import { AtmManagerService } from 'src/app/services/equipments/atm-manager.service';
import * as API from 'aws-amplify/api';
import * as Auth from 'aws-amplify/auth';
import { AtmEquipmentService } from 'src/app/services/equipments/atm-equipment.service';


@Component({
  selector: 'atm-general-status',
  templateUrl: './general-status.component.html',
  styleUrls: ['./general-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneralStatusComponent {
  @Input() set atms(equipments: AtmManagerService) {
    if(equipments) {
      this._atms = equipments;
      this.ngOnInit()
    }
  }

  @Input() set sBateryStatus(status: string | any) {
    this._sBateryStatus = status;
  }

  @Input() set vaultSensor(sensor: any) {
    this._vaultSensor = sensor
  }

  // this.atmDevices.variablesSubscription(() => this.cdk.markForCheck())
  @Input() set sGpsStatus(status: string | any) {
    this._sGpsStatus = status;
  }
  @Input() set variables(variables: VariablesService[]) {
    this._variables = variables;
    if (this._variables) {
      this.sensorRadar = this._variables.find(variable => variable.name == "Presencia_cm");
      // this.sensorLat = this._variables.find(variable => variable.name == "Latitud ATM");
      // this.sensorLong = this._variables.find(variable => variable.name == "Longitud ATM");
      this.sensorBattery = this._variables.find(variable => variable.name == "Voltage_DC");
      this.sensorNet = this._variables.find(variable => variable.name == "Connection_status");
      this.sensorVault = this._variables.find(variable => variable.name == "Boveda");
      this.sensorTray = this._variables.find(variable => variable.name == "Bandeja");
      this.sensorGeo = this._variables.find(variable => variable.name == "Latitud_y_Longitud");
      this.sensorFakeDoor = this._variables.find(variable => variable.name == "Puerta principal");
    }
  }
  /** TODO: Se debe crear una interfaz para los datos de la sucursal */
  @Input() set equipmentInfo(branch: any) {
    this._equipmentInfo = branch;
    this.equipmentId = branch.id;
    this.atmName = branch.name;
  };
  @Input() loading: boolean = true;
  @Input() hidden: boolean = false;
  @Input() name: string = '';
  @Input() active: boolean = false;

  @Input() set mainSource(mainSource: any) {
    // if (!mainSource) return;
    // this.ngOnInit();
  }

  @Input() set atm(atm: any) {
    // this.ngOnInit();
  }

  _mainSource: any;
  _atm: any;
  _atms!: AtmManagerService;

  _atsOutputStatus: string = '';
  _commercialStatus: string = '';
  _pdeStatus: string = '';
  _upsOutputSatatus: string = '';

  _atsSource: string = '';

  _mqttSubscription: any;

  _sBateryStatus: string | any;
  _sGpsStatus: string | any;
  _variables!: VariablesService[];

  sensorRadar!: VariablesService | any;
  sensorGeo!: VariablesService | any;
  sensorLat!: VariablesService | any;
  sensorLong!: VariablesService | any;
  sensorBattery!: VariablesService | any;
  sensorNet!: VariablesService | any;
  sensorVault!: VariablesService | any;
  sensorTray!: VariablesService | any;
  sensorFakeDoor!: VariablesService | any;

  mqttSubscription: any;
  sensorId = '1707532412-a839';
  bandGreen: boolean = false;
  sensors: sensorTitle = {
    geo: 'GEOLOCALIZACIÓN',
    battery: 'CAJERO ENERGIZADO',
    net: 'CAJERO OPERANDO',
    cover: 'PUERTA FALSA',
    gate: 'BÓVEDA',
    cpuCover: 'BANDEJA'
  }

  title: string = '';
  atmName: string = 'ATM';

  showMap: boolean = true;
  showBatery: boolean = false;
  showNet: boolean = false;
  showDoor: boolean = false;
  showGate: boolean = false;
  showCase: boolean = false;
  showFakeDoor: boolean = false;
  _equipmentInfo: any;
  equipmentId!: string;
  interval!: any;
  dataPoints!: { value: number, date: Date };
  isAlarma: boolean = false;
  
  reports: {countOpenVaul: number, hoursActive: number, hoursInactive: number} = {
    countOpenVaul: 0,
    hoursActive: 0,
    hoursInactive: 0
  };
  mask: boolean = false;

  // Sensores/Variables
  _vaultSensor!: any;

  constructor(private alarms: AlarmsService, private router: ActivatedRoute, private cdr: ChangeDetectorRef) { }

  async ngOnInit(): Promise<void> {
    const navigate = this.router.snapshot.paramMap.get('status');
    const lastD = this.router.snapshot.paramMap.get('lastD');
    if (navigate && this._equipmentInfo.id == 'equipos:1717624823201') {
      this.sensorGeo.lastData = { value: lastD, date: new Date() };
      this.isAlarma = true;
    }

    this.title = this.sensors.geo;
    if (this._mainSource && this._atm) {
      this.setStatus();
    }
    this.alarms.mqttSubscription((result: any) => {
      if (result) {
        clearInterval(this.interval)

        this.setInterval(async () => {
          if (this._equipmentInfo.id == 'equipos:1717525004350')
            this.isAlarma = this.sensorGeo?.lastData?.value.split('&')[1] == '-99.1875468902426' || this.sensorGeo?.lastData?.value.split('&')[1] == '-99.18850142659687'


          const authToken = (await Auth.fetchAuthSession()).tokens?.idToken?.toString()!;
          const restOperation = (await API.get({ apiName: "ATMApi", path: `/monitoring/getLatestLog/${result.S}`, options: { headers: { Authorization: authToken } } }).response).body
          restOperation.json().then((territories: any) => {
            // /**Alarma de la geocerca */
            if (territories.message.detail.EventType) {
              let status = territories.message.detail.EventType;
              if (this._equipmentInfo.id == 'equipos:1717624823201')
                if (status == "EXIT") this.isAlarma = true;
                else this.isAlarma = false;
            }
          })
        })
      }

    })

    if(this._atms) {
      this._atms.variablesSubscription((res: any) => {
        console.log(this._atms.atms[3].sensorRadar)
        this.cdr.markForCheck()
      })
    }

  }

  ngOnDestroy() {
    if (this._mqttSubscription) this._mqttSubscription.unsubscribe();
  }

  setInterval(fn: any) {
    this.interval = setInterval(fn, 100);
  }

  setStatus() {
    this._commercialStatus = [
      'inputPhaseA',
      'inputPhaseB',
      'inputPhaseC',
      'frecuency',
    ].reduce(
      (isActive, variableKey) =>
        isActive &&
        this._mainSource.variables &&
        this._mainSource.variables[variableKey] &&
        this._mainSource.variables[variableKey].sensor &&
        this._mainSource.variables[variableKey].sensor.lastData &&
        this._mainSource.variables[variableKey].sensor.lastData.value > 0,
      true
    )
      ? 'active'
      : '';
    if (this._atm)
      this._pdeStatus = [
        'outputPhaseA',
        'outputPhaseB',
        'outputPhaseC',
        'frecuency',
      ].reduce(
        (isActive, variableKey) =>
          isActive &&
          this._atm.variables &&
          this._atm.variables[variableKey] &&
          this._atm.variables[variableKey].sensor &&
          this._atm.variables[variableKey].sensor.lastData &&
          this._atm.variables[variableKey].sensor.lastData.value > 0,
        true
      )
        ? 'active'
        : '';
  }

  cloneVariable(variable: any) {
    let newVariable = Object.assign({}, variable);
    if (newVariable.sensor) {
      newVariable.sensor = Object.assign({}, newVariable.sensor);
      if (newVariable.sensor.lastData)
        newVariable.sensor.lastData = Object.assign(
          {},
          newVariable.sensor.lastData
        );
    }
    return newVariable;
  }
  /**
   * 
   * @param sensor string
   * Función  para seleccionar la grafica que se mostrara en el dashboard
   */
  handleSensorClicked(sensor: string, atm: AtmEquipmentService) {
    let sensorSelect: VariablesService;
    atm.SelectVariable()
    switch (sensor) {
      case 'sensorGeo':
        sensorSelect = atm.sensorGeo
        sensorSelect.isSelected()
        this.title = this.sensors.geo;
        break;
      case 'sensorBattery':
        sensorSelect = atm.sensorBattery
        sensorSelect.isSelected()
        this.title = this.sensors.battery;
        break;
      case 'sensorNet':
        sensorSelect = atm.sensorNet
        sensorSelect.isSelected()
        this.title = this.sensors.net;
        break;
      case 'sensorFakeDoor':
        sensorSelect = atm.sensorFakeDoor
        sensorSelect.isSelected()
        this.title = this.sensors.cover;
        break;
      case 'sensorVault':
        sensorSelect = atm.sensorVault
        sensorSelect.isSelected()
        this.title = this.sensors.gate;
        break;
      case 'sensorTray':
        sensorSelect = atm.sensorTray
        sensorSelect.isSelected()
        this.title = this.sensors.cpuCover;
        break;
      default:
        sensorSelect = atm.sensorGeo
        sensorSelect.isSelected()
        this.title = this.sensors.geo;
        break;
    }
  }

  async getReport(atm: AtmEquipmentService, type: string){
    if(type) atm.getReport(type)
  }


}