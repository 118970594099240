import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-mask',
  templateUrl: './mask.component.html',
  styleUrls: ['./mask.component.scss']
})
export class MaskComponent {
 @Input() mask!: Boolean;
 @Input() color!: string;
}
