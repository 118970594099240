<!-- Header Inicial -->
<header *ngIf="amplifyService.isLogged">
  <div class="header_container">
    <div class="header_container_navigation">
      <div class="header_container_navigation_image">
        <img src="../assets/images/DC_FONDO NEGRO.png"  style="width: 130px;" alt="" (click)="linkToMainPage()">
      </div>
    </div>
    <div class="header_container_all">
      <div class="header_container_upper">
        <div class="header_text">
          <p><b>{{"SUCURSALES"}}</b></p>
        </div>
        <div class="header_container_user">
          <button mat-button [matMenuTriggerFor]="menu" class="btnUser"><mat-icon><img src="../assets/icons/usuario.svg"></mat-icon></button><p>Rogelio García</p>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="closeSession()">Cerrar sesión</button>
          </mat-menu>
        </div>
      </div>
      <div class="line"></div>
    </div>
  </div>
</header>
<div [ngClass]="amplifyService.isLogged ? 'content' : '' ">
  <router-outlet></router-outlet>
</div>


<div class="clock_date" *ngIf="amplifyService.isLogged">
  <div class="date">
    <div class="time">{{currentDate | date:'dd'}}</div>
    <div class="time">{{currentDate | date:'MM'}}</div>
    <div class="time">{{currentDate | date:'yy'}}</div>
  </div>
  <div class="clock">
    {{ currentDate | date:'shortTime' }}
  </div>
</div>

<footer class="footer">
  <div class="footer__text">OJO DE <span style="color: #ffd50e;">SAURÓN</span></div>
</footer>