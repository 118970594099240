import { Component, EventEmitter, Input, Output } from '@angular/core';
import { sensorTitle } from 'src/app/interfaces/variable';
import { AlarmsService } from 'src/app/services/mqtt/alarms.service';

@Component({
  selector: 'app-sensors',
  templateUrl: './sensors.component.svg',
  styleUrls: ['./sensors.component.scss'],
})
export class SensorsComponent {
  @Input() set batteryStatus(status: any) { this._batteryStatus = status; }
  @Input() set gpsStatus(status: any) { this.isAlarma = status; }
  @Input() netStatus!:any;
  @Input() fakeDoorStatus!:any;
  @Input() VaultStatus!:any;
  @Input() cpuDoorStatus!:any;
  @Input() sensorsTitle!: sensorTitle;
  @Output() sensorClicked: EventEmitter<string> = new EventEmitter<string>();
  @Input() equipmentInfo!:any;
  @Input() set long(l:any){
    this.l = l;
  }
  @Input() atmName!:string;
  _batteryStatus: any;
  _gpsStatus: any
  interval!:any;
  isAlarma: boolean = false;
  l:any;
  constructor( private alarms: AlarmsService){

  }

  ngOnInit(){ }
  
  handleSensorClick(sensor: string): void {
    this.sensorClicked.emit(sensor);
  }

  capitalizeFirstLetter(value: string): string {
    if (!value) return value;
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }


  setInterval(fn:any){
    this.interval = setInterval(fn,100);
  }
}
