import * as auth from 'aws-amplify/auth';
export const AWS_AUTH_CREDENTIALS: any = {
    Auth: {
      Cognito: {
        userPoolId: "us-east-1_TUpXj4qPW",
          userPoolClientId: "61m5uitc70664ld1v5t5h5vgrj",
          identityPoolId: "us-east-1:79acd9ba-ab3f-4f75-9ed5-978c7b9fdfef"
      }
    },
    API: {
      REST: { 
        ATMApi: { endpoint: "https://dm5fp9fzrl.execute-api.us-east-1.amazonaws.com/prod", region: "us-east-1" } 
      }
    }
  };