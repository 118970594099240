import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatListModule } from "@angular/material/list";
import { MatButtonModule } from "@angular/material/button";
import { MatSelectModule } from "@angular/material/select";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatCardModule } from "@angular/material/card";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";

import { MainDashboardComponent } from './components/main-dashboard/main-dashboard.component';
import { ServiceAmplifyService } from './services/amplify/service-amplify.service'
import { MonitoringComponent } from './components/monitoring/monitoring.component';
import { LocationComponent } from './widgets/location/location.component';
import { ElectricalEquipmentSummaryComponent } from './widgets/electrical-equipment-summary/electrical-equipment-summary.component';
import { DonutChartComponent } from './widgets/donut-chart/donut-chart.component';
import { GeneralStatusComponent } from './components/general-status/general-status.component';
import { RouteBarComponent } from './widgets/route-bar/route-bar.component';
import { NgChartsModule } from 'ng2-charts';
import { LoginModule } from './components/login/login.module';
import { AtmStatusWidgetComponent } from './widgets/atm-status-widget/atm-status-widget.component';
import { ChartComponent } from './widgets/chart/chart.component';
import { MapWidgetComponent } from './widgets/map-widget/map-widget.component';
import { MapDashboardComponent } from './components/map-dashboard/map-dashboard.component';
import { TitleIconComponent } from './components/title-icon/title-icon.component';
import { StateIdToFullNamePipe } from './pipes/state-id-to-full-name.pipe';
import { SensorsComponent } from './widgets/sensors/sensors.component';
import { LocationMapComponent } from './components/location-map/location-map.component';
import { RadarComponent } from './widgets/radar/radar.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatBadgeModule } from '@angular/material/badge';
import { ChartDashboardComponent } from './components/chart-dashboard/chart-dashboard.component';

@NgModule({
  declarations: [
    AppComponent,
    MainDashboardComponent,
    MonitoringComponent,
    LocationComponent,
    ElectricalEquipmentSummaryComponent,
    DonutChartComponent,
    GeneralStatusComponent,
    RouteBarComponent,
    AtmStatusWidgetComponent,
    ChartComponent,
    MapWidgetComponent,
    RadarComponent,
    MapDashboardComponent,
    TitleIconComponent,
    StateIdToFullNamePipe,
    SensorsComponent,
    LocationMapComponent,
    RadarComponent,
    ChartDashboardComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatSidenavModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatIconModule,
    MatMenuModule,
    MatListModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatSelectModule,
    MatExpansionModule,
    MatCardModule,
    NgChartsModule,
    LoginModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMatDatetimePickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatBadgeModule
  ],
  providers: [ServiceAmplifyService],
  bootstrap: [AppComponent]
})
export class AppModule { }
