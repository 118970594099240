import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators } from '@angular/forms';
import { AlarmsService } from 'src/app/services/mqtt/alarms.service';

@Component({
  selector: 'app-main-dashboard',
  templateUrl: './main-dashboard.component.html',
  styleUrls: ['./main-dashboard.component.scss'],
})
export class MainDashboardComponent implements OnInit {
  states = [
    { name: 'CDMX' },
    { name: 'Oaxaca' },
    { name: 'Estado de México' },
    { name: 'Morelos' },
    { name: 'Chiapas' },
    { name: 'Morelos' },
    { name: 'Querétaro' },
  ];

  arregloNew = new FormControl('', [
    Validators.required,
    Validators.pattern(/^\d+$/),
  ]);

  dataShops: any = [
    {
      shop_number: 542,
      name: 'Tienda 1',
      address: 'Insurgentes Sur 3579 - Tlalpan, La Joya CP 03100',
    },
    {
      shop_number: 231,
      name: 'Tienda 2',
      address: 'Insurgentes Sur 3690 - Tlalpan, La Joya CP 03100',
    },
    {
      shop_number: 425,
      name: 'Tienda 3',
      address: 'Anillo Perif. 4690 - Coyoacán CP 04530',
    },
    {
      shop_number: 335,
      name: 'Tienda 4',
      address: 'Insurgentes Sur 3868 - Tlalpan Centro I, Tlalpan, 14000',
    },
    {
      shop_number: 567,
      name: 'Tienda 5',
      address:
        'Av. Aztecas 598, Ajusco, Coyoacán, 04300 Ciudad de México, CDMX',
    },
    {
      shop_number: 765,
      name: 'Tienda 6',
      address:
        'Av. de los Insurgentes Sur 3579, La Joya, Tlalpan, 14090 Ciudad de México, CDMX',
    },
    {
      shop_number: 987,
      name: 'Tienda 7',
      address:
        'Jiumate 79, 35C, Pedregal de Santo Domingo, Coyoacán, 04369 Coyoacán, CDMX',
    },
    {
      shop_number: 543,
      name: 'Tienda 8',
      address: 'Insurgentes Sur 3579 - Tlalpan, La Joya CP 03100',
    },
    {
      shop_number: 242,
      name: 'Tienda 9',
      address: 'Insurgentes Sur 3690 - Tlalpan, La Joya CP 03100',
    },
    {
      shop_number: 435,
      name: 'Tienda 10',
      address: 'Anillo Perif. 4690 - Coyoacán CP 04530',
    }
  ];
  auxShop: any[] = this.dataShops;

  constructor(private router: Router, private route: ActivatedRoute, private alarms: AlarmsService) {}
  stateId: string = '';

  ngOnInit() {
    if (this.route.snapshot.params['stateId']) {
      this.stateId = this.route.snapshot.params['stateId'];
    }
  }

  goToBranch(branchId: string) {
    this.router.navigate(['monitoring', this.stateId, branchId]);
  }

  filtroTienda(inputValue: any) {
    const digitRegExp: RegExp = /^\d+$/;

    if (inputValue.length >= 1 && digitRegExp.test(inputValue)) {
      this.auxShop = this.dataShops.filter((data: { shop_number: string }) => {
        const inputValueRegex = new RegExp(`^${inputValue}`);
        return inputValueRegex.test(data.shop_number.toString());
      });
    }
    if (this.arregloNew.value == '') this.auxShop = this.dataShops;
  }
}
