import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MediaMatcher } from "@angular/cdk/layout";
import * as Auth from 'aws-amplify/auth';
import { PubSub } from '@aws-amplify/pubsub';
import { ServiceAmplifyService } from './services/amplify/service-amplify.service';
import { AlarmsService } from './services/mqtt/alarms.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  mobileQuery!: MediaQueryList;
  private _mobileQueryListener!: () => void;
  narrowQuery!: MediaQueryList;
  private _narrowQueryListener!: () => void;
  user: any;
  userGroups: string[] = [];

  loadingProjects: boolean = false;

  pathArray = [];
  params: any = {};
  queryParams: any = {};
  signedIn: boolean = true;
  loaderMessage: string = "";
  connectionMqtt: any;
  audioActive: boolean = false;
  audio!: HTMLAudioElement;
  currentDate: Date = new Date();
  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private route: ActivatedRoute, private router: Router, public amplifyService: ServiceAmplifyService) {

  }

  async ngOnInit() {
    let userLogged = await this.amplifyService.currentSession();
    if (userLogged.tokens) this.amplifyService.isLogged = true;
    this.initPubSub();
    setInterval(() => {
      this.currentDate = new Date();
    }, 1000);
    if (this.audioActive) {

    }
  }

  initPubSub() {
    //TODO: Fragmento de codigo encargado de obtener todas las alertas de todos los tópicos
    // alarms/#
    // data/ekt-energy/#
    // this.connectionMqtt = new PubSub({region: "us-east-1", endpoint: "wss://am8evgk8jx2uj-ats.iot.us-east-1.amazonaws.com/mqtt"})
    // this.connectionMqtt.subscribe({topics: ["data/ekt-energy/#"]}).subscribe((res: any) =>{
    //   console.log("Respuesta", res)
    // })
    // const pubsub = new PubSub({
    //   region: "us-east-1",
    //   endpoint: "wss://am8evgk8jx2uj-ats.iot.us-east-1.amazonaws.com/mqtt",
    //   clientId: 'alarmClient:' + new Date().getTime()
    // })

    // pubsub.subscribe({topics: ["alarms/#"]}).subscribe(response => console.log(response))
  }
  setPath() {
    // let view = this.route.snapshot;
  }

  closeSession() {
    Auth.signOut().finally(() => location.reload());
  }

  linkToMainPage() {
    this.router.navigate(["/map-dashboard"])
  }

}
