<div style="position: relative;">
    <app-mask [mask]="mask" [color]="'#9d9d9c'"></app-mask>
    <div>
        <button class="container_monitoring_cards_card_btn_btn" mat-flat-button (click)="getReport(_atm, 'hour')">Reporte Diario</button>
        <button class="container_monitoring_cards_card_btn_btn" mat-flat-button (click)="getReport(_atm, 'month')">Reporte Mensual</button>  
    </div>
    <div style="height: 200px; display: flex; justify-content: center; flex-direction: column;">
        <div>Horas sin transaccionar: {{_atmReports.hoursInactive}} Hrs</div>
        <div>Horas transaccionando: {{_atmReports.hoursActive}} Hrs</div>
        <div>Numero de transacciones: {{_atmReports.numberTransactions}}</div>
        <div>Boveda abierta: {{_atmReports.countOpenVaul || 0}} eventos</div>
        <div>Tiempo promedio por usuario: {{_atmReports.timeForUser}} segundos</div>
    </div>
</div>