import { Component } from '@angular/core';

@Component({
  selector: 'atm-status-widget',
  templateUrl: './atm-status-widget.component.svg',
  styleUrls: ['./atm-status-widget.component.scss']
})
export class AtmStatusWidgetComponent {

}
