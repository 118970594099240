import { Injectable } from '@angular/core';
import { PubSub } from '@aws-amplify/pubsub';
import { Subject } from 'rxjs';
import { environment } from 'src/enviroments/envitoment';


@Injectable({
  providedIn: 'root'
})
export class AlarmsService {

  mqttAlarmOberver: Subject<any> = new Subject();

  constructor() {
    this.startComunication();
  }

  startComunication() {
    const pubsub = new PubSub({
      region: "us-east-1",
      endpoint: "wss://am8evgk8jx2uj-ats.iot.us-east-1.amazonaws.com/mqtt",
      clientId: 'alarmClient:' + new Date().getTime()
    })

    pubsub.subscribe({ topics: ["alarms/#"] }).subscribe(response => {
      this.mqttAlarmOberver.next(this.filterSensor(response));
    })
  }

  filterSensor(alarm: any) {
    if (alarm.S == '1717638081430-574f')
      return alarm;
  }

  mqttSubscription(fn: any) {
    return this.mqttAlarmOberver.subscribe(fn)
  }


}
