import { Component, Input } from '@angular/core';

@Component({
  selector: 'ekt-electrical-equipment-summary',
  templateUrl: './electrical-equipment-summary.component.html',
  styleUrls: ['./electrical-equipment-summary.component.scss']
})
export class ElectricalEquipmentSummaryComponent {
  @Input() loading: boolean = true;
  @Input() hidden: boolean = false;
  @Input() name: string = '';
  @Input() active:boolean = false;
  @Input() set equipment(equipment: any) {
    this._equipment = equipment;
    this.setSensors();
  }

  _equipment: any;
  _donutVariablesNames: string[] = ["battery", "load", "consumption", "power", "temperature"];
  _donutVariables: any[]=[];
  _listVariablesNames: string[] = ["inputVoltage", "outputVoltage", "inputPhaseA", "inputPhaseB", "inputPhaseC", "outputPhaseA", "outputPhaseB", "outputPhaseC", "powerFactor", "thd"];
  _listVariables: any[]=[];
  _remainingTime: any;

  _mqttSubscription: any;

  constructor() {}

  ngOnInit() {}

  setSensors() {
    this._donutVariables = [];
    this._listVariables = [];
    if (this._equipment && this._equipment.variables) {
      this._donutVariablesNames.forEach(sensorName => {
        if (this._equipment.variables[sensorName]) this._donutVariables.push(this.cloneVariable(this._equipment.variables[sensorName]));
      });
      this._donutVariables.forEach(variable => {
        variable.dataToShow = {
          name: variable.name,
          units: variable.sensor ? variable.sensor.units : "",
          value: variable.sensor && variable.sensor.lastData ? variable.sensor.lastData.value : null,
          date: variable.sensor && variable.sensor.lastData ? variable.sensor.lastData.date : null,
          min: this._equipment.type == "UPS" && (variable.name == "Batería" || variable.name == "Carga") ? 0 : null,
          max: this._equipment.type == "UPS" && (variable.name == "Batería" || variable.name == "Carga") ? 100 : null
        };
      });
      this._listVariablesNames.forEach(sensorName => {
        if (this._equipment.variables[sensorName]) this._listVariables.push(this.cloneVariable(this._equipment.variables[sensorName]));
      });
      this._listVariables.forEach(variable => {
        if (variable.sensor && variable.sensor.lastData) {
          let dStrings = new Date(variable.sensor.lastData.date).toString().split(" ");
          variable.sensor.lastData.dateString = [dStrings[1], dStrings[2], dStrings[3], dStrings[4]].join(" ");
        }
      });
      this._remainingTime = this._equipment.variables.remainingTime || null;
      if (this._remainingTime && this._remainingTime.sensor && this._remainingTime.sensor.lastData) {
        let dStrings = new Date(this._remainingTime.sensor.lastData.date).toString().split(" ");
        this._remainingTime.sensor.lastData.dateString = [dStrings[1], dStrings[2], dStrings[3], dStrings[4]].join(" ");
      }
    }
  }
  cloneVariable(variable:any) {
    let newVariable = Object.assign({}, variable);
    if (newVariable.sensor) {
      newVariable.sensor = Object.assign({}, newVariable.sensor);
      if (newVariable.sensor.lastData) newVariable.sensor.lastData = Object.assign({}, newVariable.sensor.lastData);
    }
    return newVariable;
  }
}
