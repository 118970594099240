import {
  Component,
  OnInit,
  Renderer2,
  ElementRef,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-map-widget',
  templateUrl: './map-widget.component.svg',
  styleUrls: ['./map-widget.component.scss'],
})
export class MapWidgetComponent implements OnInit {
  @Output() stateEvent: EventEmitter<string> = new EventEmitter<string>();
  @Input() set paintAlertedStates(paintAlert: boolean) {
    const cdmx = document.getElementById('cdmx');
    if(paintAlert){
      cdmx?.classList.remove('available');
      cdmx?.classList.add('alerted');
    }else{
      cdmx?.classList.remove('alerted');
      cdmx?.classList.add('available');
    }
    
  }
  @Input() set territoriesAvailables(territories: any) {
    if (territories) {
      this._territories = territories;
      this._territories = this._territories.map((t: any) => {
        t.name =
          t.name == 'EjemploMaster'
            ? 'sonora'
            : t.name == 'Sauron Lab'
            ? 'chihuahua'
            : t.name == 'Estado de México'
            ? 'cdmx'
            : t.name;
        return t;
      });
      this.setAvailability();
      this.ngOnInit();
      // console.log(this._territories)
    }
  }
  stateElements: any;
  territories: { id: string; available: boolean; alerted: boolean }[] = [
    { id: 'aguascalientes', available: false, alerted: false },
    { id: 'bcn', available: false, alerted: false },
    { id: 'bcs', available: false, alerted: false },
    { id: 'campeche', available: false, alerted: false },
    { id: 'chiapas', available: false, alerted: false },
    { id: 'chihuahua', available: false, alerted: false },
    { id: 'cdmx', available: false, alerted: false },
    { id: 'coahuila', available: false, alerted: false },
    { id: 'colima', available: false, alerted: false },
    { id: 'durango', available: false, alerted: false },
    { id: 'estado_de_mexico', available: false, alerted: false },
    { id: 'guanajuato', available: false, alerted: false },
    { id: 'guerrero', available: false, alerted: false },
    { id: 'hidalgo', available: false, alerted: false },
    { id: 'jalisco', available: false, alerted: false },
    { id: 'michoacan', available: false, alerted: false },
    { id: 'morelos', available: false, alerted: false },
    { id: 'nayarit', available: false, alerted: false },
    { id: 'nuevo_leon', available: false, alerted: false },
    { id: 'oaxaca', available: false, alerted: false },
    { id: 'puebla', available: false, alerted: false },
    { id: 'queretaro', available: false, alerted: false },
    { id: 'quintana_roo', available: false, alerted: false },
    { id: 'san_luis_potosi', available: false, alerted: false },
    { id: 'sinaloa', available: false, alerted: false },
    { id: 'sonora', available: false, alerted: false },
    { id: 'tabasco', available: false, alerted: false },
    { id: 'tamaulipas', available: false, alerted: false },
    { id: 'tlaxcala', available: false, alerted: false },
    { id: 'veracruz', available: false, alerted: false },
    { id: 'yucatan', available: false, alerted: false },
    { id: 'zacatecas', available: false, alerted: false },
  ];

  _territories: any;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private router: Router
  ) {}

  ngOnInit(): void {
    // Add event listeners for each state
    if(this._territories){
      this.stateElements = this.el.nativeElement.querySelectorAll('path.cls-1');
      this.stateElements.forEach((stateElement: HTMLElement) => {
        this.territories.forEach(t => {
          if(t.id == stateElement.id){
            if(t.available) stateElement.classList.add("available")
            else stateElement.classList.add("unavailable")
          }
        })
        this.renderer.listen(stateElement, 'click', () => {
          this.stateClicked(stateElement.id);
        });

        this.renderer.listen(stateElement, 'dblclick', () => {
          this.stateDblClicked(stateElement.id);
        });

        this.renderer.listen(stateElement, 'mouseenter', () => {
          this.renderer.addClass(stateElement, 'state-hover');
        });

        this.renderer.listen(stateElement, 'mouseleave', () => {
          this.renderer.removeClass(stateElement, 'state-hover');
        });
      });
    }
  }

  stateClicked(stateId: string): void {
    //color this state
    this.colorNewState(stateId);
    this.stateEvent.emit(stateId);
  }

  stateDblClicked(stateId: string): void {
    this.router.navigate(['monitoring', stateId, 'branches']);
  }

  colorNewState(stateId: string) {
    const className = 'selected-state';
    //remove the class from the svg
    this.stateElements.forEach((element: HTMLElement) => {
      element.classList.remove(className);
    });

    const elements = this.el.nativeElement.querySelectorAll(`#${stateId}`);
    elements.forEach((element: HTMLElement) => {
      element.classList.add(className);
    });
  }

  setAvailability(){
    this.territories = this.territories.map(t => {
      this._territories.map((_t: any) => {
        if(_t.name == t.id) t.available = true; 
      })
      return t
    })
  }
}
