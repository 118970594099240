export const environment = {
    production: false,
    userPoolId: "us-east-1_sBdk7EmdK",
    userPoolWebClientId: "7bmdf2vvr4n3o9bmg8pcfrrt8o",
    ApiRestEndpoint: "https://dm5fp9fzrl.execute-api.us-east-1.amazonaws.com/prod",
    identity_pool_id: "us-east-1:73b79710-f235-453d-b2c1-deac57e08ffe",
    cognito_idp: "cognito-idp.us-east-1.amazonaws.com/us-east-1_sBdk7EmdK",
    iot_host: "am8evgk8jx2uj-ats.iot.us-east-1.amazonaws.com",
    base_domain: "dev-ekt-energy.iot-gs.net",
    region: "us-east-1",
    serviceWorker: false
  };