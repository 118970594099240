import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Chart } from "chart.js";
import { ChartData, ChartEvent, ChartType } from 'chart.js';
@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss']
})
export class DonutChartComponent {
  @ViewChild("canvas") canvas!: ElementRef<HTMLCanvasElement>;
  @Input() min: any = 0;
  @Input() max: any = 0;
  @Input() set data(data: any) {
    this._data = data;
    if (!data) return;
    this.changing = true;
    setTimeout(() => {
      this.update();
      this.changing = false;
    }, 250);
  }
  @Input() set variable(variable: any) {
    this._variable = variable;
    if (!variable) return;
    this.changing = true;
    setTimeout(() => {
      this.update();
      this.changing = false;
    }, 250);
  }
  _data: any = undefined;
  _variable: any;

  changing: boolean = false;
  _value: number = 0;
  _valueToShow: number = 0;
  _units: string = '';
  _unitsToShow: string = '';
  _date: any;
  _dateToShow: string = '';

  chartLine: any;
  colors: any = {
    main: "#f5d932"
  };
  cutouts: any = {
    thicknessBorder: 93,
    mediumBorder: 89,
    thickBorder: 85
  };

  chartOpts: any = {
    type: "doughnut",
    data: {
      datasets: [
        {
          data: this.max !== undefined && this.min !== undefined ? [this._value, this.max - this.min] : [100, 0],
          backgroundColor: [this.colors.main, "#eee"],
          borderWidth: 0,
          label: "none"
        }
      ]
    },
    options: {
      title: {
        display: false
      },
      legend: {
        display: false
      },
      cutout:'30',
      // cutoutPercentage: this._data && this._data.hasOwnProperty("max") && this._data.hasOwnProperty("min") ? this.cutouts.mediumBorder : this.cutouts.thicknessBorder,
      tooltips: {
        enabled: false
      },
      responsive: true,
      animation: {
        animateScale: false,
        animateRotate: true
      },
      aspectRatio: 1,
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }
      }
    }
  };
  constructor() { }

  ngOnInit() {
    this.initChart();
    if (this._data.name == "Batería")
      this._data.name = 'Fuente'
    if (this._data.name == "Carga")
      this._data.name = 'Batería'
  }
  
  ngOnDestroy() { }

  initChart() {
    this.update();
  }

  update() {
    if (this.chartLine) this.chartLine.destroy();
    this._valueToShow = this._data.value;
    this._unitsToShow = this._data.units;
    let dStrings = new Date(this._data.date).toString().split(" ");
    this._dateToShow = [dStrings[1], dStrings[2], dStrings[3], dStrings[4]].join(" ");
    if (this._data.value >= 1000) {
      this._valueToShow /= 1000;
      this._valueToShow = parseFloat(this._valueToShow.toFixed(1));
      if (this._data.units[0].toLowerCase() === "k") this._unitsToShow = this.replaceAt(this._data.units, 0, "M");
      else if (this._data.units[0].toLowerCase() === "m") this._unitsToShow = this.replaceAt(this._data.units, 0, "G");
      else this._unitsToShow = this.replaceAt(this._data.units, 0, "K");
    }
    if (typeof this._data.max == "number" && typeof this._data.min == "number") {
      this.chartOpts.data.datasets[0].data = [this._valueToShow, this._valueToShow - (this._data.max - this._data.min)];
      this.chartOpts.options.cutoutPercentage = this._data && this._data.hasOwnProperty("max") && this._data.hasOwnProperty("min") ? (this._valueToShow / (this._data.max - this._data.min) >= 0.9 ? this.cutouts.thickBorder : this.cutouts.mediumBorder) : this.cutouts.thicknessBorder;
    } else {
      this.chartOpts.data.datasets[0].data = [100, 0];
      this.chartOpts.options.cutoutPercentage = this.cutouts.thicknessBorder;
    }
    let ctx = this.canvas.nativeElement;
    this.chartLine = new Chart(ctx, this.chartOpts);
  }

  replaceAt(string: string, index: number, replace: string) {
    return string.substring(0, index) + replace + string.substring(index + 1);
  }
}
