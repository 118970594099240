import { variable } from "src/app/interfaces/variable";
import moment, * as Moment from "moment"

export class VariablesService {
  public name!: String;
  public description!: String;
  public id!: String;
  public deviceId!: String;
  public lastData!: any;
  public parent!: String;
  public protocol!: String;
  public variable!: String;

  public status!: String;
  public selectVariable!: boolean;

  constructor(sensor: variable) {
    this.name = sensor.name;
    this.description = sensor.description;
    this.id = sensor.id;
    this.deviceId = sensor.deviceId;
    this.parent = sensor.parent;
    this.protocol = sensor.protocol;
    this.variable = sensor.variable;
    this.selectVariable = this.name == "Latitud_y_Longitud" ? true : false;;

    this.setLastData(sensor.lastData);
  }

  public setData(valueSensor: any){
    this.lastData = {value: valueSensor?.V, date: new Date()}
  }

  setLastData(data: any): void {
    if (!data) return;
    if (data.date && typeof data.value == 'number') this.lastData = {date: data.date, value: data.value};
    else if (data.S && typeof data.V == 'number') this.lastData = { date: new Date(), value: data.V };
  }

  public checkData(){
    
    if(this.name == "Voltage_DC") this.status = this.lastData && this.lastData?.value < 11.90 ? "error" : "ok"
    
    if(this.name == "Cajero Operando") this.status = this.lastData && this.lastData?.value > 0 ? "error" : "ok"

    if(this.name == "Boveda") this.status = this.lastData && this.lastData?.value != 0 ? "error" : "ok"

    if(this.name == "Bandeja") this.status = this.lastData && this.lastData?.value != 0 ? "error" : "ok"
    
    if(this.name == "Puerta principal") this.status = this.lastData && this.lastData?.value != 0 ? "error" : "ok"
    
   
  }

  public isSelected(){
    this.selectVariable = !this.selectVariable
  }


//   setLastValidData(data?: any) {
//     this.lastValidData = [data, this.lastData, this.lastValidData]
//         /**
//          * Se filtran los datas que existen y que tienen un valor válido
//          */
//         .filter(dat => dat?.date && typeof dat?.value == "number" && VariablesUtils._isValidDataByValue(dat, this.variable))
//         /**
//          * Se ordenan los datas por tiempo de dorm descendente.
//          * Se retorn a el primer elemento del array ya ordenado.
//          */
//         .sort((dA, dB) => (+moment(dB.date) - +moment(dA.date)))[0];
// }
}