import { Subject } from 'rxjs';
import { VariablesService } from '../variables/variables.service';
import { PubSub } from '@aws-amplify/pubsub';
import { environment } from 'src/enviroments/envitoment';

export class MqttService {
  private variables: VariablesService[];
  mqttOberver: Subject<any> = new Subject();

  private numberOfVariablesUpdated: number = 0;
  private waitingForVariablesData: boolean = false;

  constructor(variables: VariablesService[]) {

    this.variables = variables;
    this.startCommunication();

  }

  mqttSubscription(fn: any) {
    return this.mqttOberver.subscribe(fn)
  }

  mqttCloseSubscription() {
    this.mqttOberver.complete();
  }

  startCommunication() {
    let topics = this.variables.map(variable => `data/ekt-energy/${variable.deviceId}`);
    new PubSub({ region: "us-east-1", endpoint: `wss://${environment.iot_host}/mqtt` }).subscribe({ topics: topics }).subscribe((response: any) => {
      let sensorData = this.variables.find(variable => variable.id == response.S);
      if (sensorData) {
        sensorData.setLastData(response)
        this.checkForAllVariablesUpdated()
      }
    })
  }

  private _onVariablesUpdated() {
    if (this.waitingForVariablesData) {
        this.mqttOberver.next(this.variables);
    }
    this.numberOfVariablesUpdated = 0;
    this.waitingForVariablesData = false;
}

private checkForAllVariablesUpdated() {
    this.numberOfVariablesUpdated = this.numberOfVariablesUpdated || 0;
    this.numberOfVariablesUpdated++;
    if (!this.waitingForVariablesData) {
        this.waitingForVariablesData = true;
        setTimeout(() => {
            this._onVariablesUpdated();
        }, 2000);
    }
    if (this.numberOfVariablesUpdated > 0 && this.numberOfVariablesUpdated === this.variables.length) this._onVariablesUpdated();
}
}
