import { Component, Input } from '@angular/core';
import { AtmEquipmentService } from 'src/app/services/equipments/atm-equipment.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent {
   @Input() set atm(atm: AtmEquipmentService){
    if(atm) {
      this._atm = atm
      // this.getReport(this._atm, "hour")
      this._atmReports = this._atm.reports
    }
   }

   mask: boolean = false;
   _atm!:AtmEquipmentService;
   _atmReports: {countOpenVaul: number, hoursActive: number, hoursInactive: number, numberTransactions: number, timeForUser: number} = {
    countOpenVaul: 0,
    hoursActive: 0,
    hoursInactive: 0,
    numberTransactions: 0,
    timeForUser: 0
  };
;

   async getReport(atm: AtmEquipmentService, type: string){
    this.mask = true;
    await atm.getReport(type)
    this._atmReports = atm.reports
    this.mask = false;
   }

}
