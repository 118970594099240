import { Subject } from 'rxjs';
import { MqttService } from '../mqtt/mqtt.service';
import { VariablesService } from './variables.service';
import { variable } from 'src/app/interfaces/variable';
import { GpsService } from './gps.service';

export class VariablesManagerService {
  private variablesSubject: Subject<any> = new Subject();
  private mqttService!: MqttService;

  public variables!: VariablesService[];
  public gps!: GpsService;

  public sensorGeolocation!: any;

  constructor(sensorVariables: variable[]) {
    this.variables = sensorVariables.map( (variable: variable) => new VariablesService(variable));

    let sLatitude = this.variables.find(variable => variable.name == "Latitud ATM");
    let sLogitud = this.variables.find(variable => variable.name == "Longitud ATM");
    this.gps = new GpsService(sLatitude, sLogitud);

    this.mqttService = new MqttService(this.variables);
    this.mqttService.mqttSubscription((variables: any) => {
      this.variablesSubject.next(variables);
      this.checkData()
    });

    this.checkData()
  }


  variablesSubscription(fn: any){
    return this.variablesSubject.subscribe(fn)
  }

  checkData(){
    this.variables.forEach(variable => {
      variable.checkData()
    })
  }

}
