<div class="general-status-container">
    <div class="left-container">
        <mat-card class="control-card">
            <mat-card-header>
                <mat-card-title class="card-title-header">PDE 1<mat-icon
                        class="mat-icon-status">circle</mat-icon></mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div style="text-align: center; cursor: pointer;" (click)="procesarOpcion('circle')">
                    <div>
                        <div style="position: relative;"><app-pde [active]="frequency.lastData?.value > 50"></app-pde></div>
                        <div style="position: absolute; left: 47%; top: 43%; font-size: 21px;">{{frequency.lastData?.value || 0}} Hz</div>
                    </div>
                </div>
                <!-- <button (click)="procesarOpcion('circle')" [ngClass]="frequency.lastData?.value > 50 ? 'main-btn ok' : 'main-btn'">{{frequency.lastData?.value || 0}} Hz</button> -->
                
            </mat-card-content>
            <br>
            <p>MODOS</p>
            <mat-card-actions class="card-actions-primary">
                <button mat-button [class.selected-mode-btn]="selectedModeBtn === 'Aut'"
                    (click)="selectModeButton('Aut')">Aut</button>
                <button mat-button [class.selected-mode-btn]="selectedModeBtn === 'Man'"
                    (click)="selectModeButton('Man')">Man</button>
                <button mat-button [class.selected-mode-btn]="selectedModeBtn === 'Test'"
                    (click)="selectModeButton('Test')">Test</button>
                <button mat-button [class.selected-mode-btn]="selectedModeBtn === 'Bloq'"
                    (click)="selectModeButton('Bloq')">Bloq</button>
            </mat-card-actions>
            <mat-card-actions class="card-actions-secondary">
                <button mat-button (click)="procesarOpcion('Vin/Vout')" [class.selected-mode-btn]="selectedModeBtnOpc === 'Vin/Vout'">
                    <mat-icon class="mat-icon-flash">flash_on</mat-icon>Vin/Vout
                </button>
                <button mat-button (click)="procesarOpcion('Corrientes')" [class.selected-mode-btn]="selectedModeBtnOpc === 'Corrientes'">
                    <mat-icon class="mat-icon-flash">flash_on</mat-icon>Corrientes
                </button>
                <button mat-button (click)="procesarOpcion('Potencias')" [class.selected-mode-btn]="selectedModeBtnOpc === 'Potencias'">
                    <mat-icon class="mat-icon-flash">flash_on</mat-icon>Potencias
                </button>
            </mat-card-actions>
            <mat-slide-toggle [(ngModel)]="isChecked" class="big-toggle"></mat-slide-toggle>
        </mat-card>
    </div>
    <div class="right-container">
        <h1 class="graph-title">
            <span class="text">PLANTA DE EMERGENCIA</span>
        </h1>
        <mat-card class="control-card">
            
            <br><br>
            <div class="info-container">
                <div class="left-column" style="margin-top: 20px; margin-right: 10px;">
                    <div class="pde-title" style="margin: 10px;">PDE 1</div>
                    <mat-slide-toggle [(ngModel)]="isChecked" class="big-toggle"></mat-slide-toggle>
                    <p>{{ sensorSelected.description }}</p>
                    <p>Último valor {{sensorSelected.lastData?.value || 0}}</p>
                    <p>{{(sensorSelected.lastData?.date || auxDate) | date: "shortTime" }}</p>
                    <div class="arrows-container">
                        <mat-icon class="mat-icon-arrow">arrow_drop_up</mat-icon> <!-- Volume Up Arrow -->
                        <mat-icon class="mat-icon-arrow">arrow_drop_down</mat-icon> <!-- Volume Down Arrow -->
                    </div>
                </div>
                <div class="right-column">
                    <div class="top-row">
                        <div class="general-card monitoring-data">
                            <div class="monitoring-data-chart">
                                <div class="monitoring-data-chart-chart">
                                    <div *ngIf="circle" class="monitoring-chart">
                                        <app-chart-dashboard [data]="rpm?.lastData" [equipmentId]="1 + 'battery'" [variableId]="rpm.id"></app-chart-dashboard>
                                    </div>
                                    <div *ngIf="VinVout" class="monitoring-chart">
                                        <app-chart-dashboard [data]="voltageL1?.lastData" [equipmentId]="2 + 'battery'" [variableId]="voltageL1.id"></app-chart-dashboard>
                                    </div>
                                    <div *ngIf="Corrientes" class="monitoring-chart">
                                        <app-chart-dashboard [data]="mCorrientes?.lastData" [equipmentId]="3 + 'net'" [variableId]="mCorrientes.id"></app-chart-dashboard>
                                    </div>
                                    <div *ngIf="Potencias" class="monitoring-chart">
                                        <app-chart-dashboard [data]="mPotencias?.lastData" [equipmentId]="4 + 'vault'" [variableId]="mPotencias.id"></app-chart-dashboard>
                                    </div>
 
                                </div>

                            </div>

                        </div>

                        <!-- <div class="selected-graph-container"></div> -->
                        <!-- <div class="monitoring-chart">
                            <app-chart-dashboard [data]="okGenerator?.lastData" [equipmentId]="1 + 'tray'"></app-chart-dashboard>
                        </div> -->
                    </div>
                    <div class="bottom-row">
                        <!-- <div class="data-grid">
                            <div class="grid-item" *ngFor="let item of dummyInfoArray">
                                <div class="left">{{ item.left }}</div>
                                <div class="right">{{ item.right }}</div>
                            </div>
                        </div> -->
                        <div class="data-grid" *ngIf="VinVout">
                            <div class="grid-item">
                                <div class="left">Tension en fase 1 del generador</div>
                                <div class="right">{{ voltageL1.lastData?.value || 0}} V</div>
                            </div>
                            <div class="grid-item">
                                <div class="left">Tension en fase 1 del generador</div>
                                <div class="right">{{ voltageL2.lastData?.value || 0}} V</div>
                            </div>
                            <div class="grid-item">
                                <div class="left">Tension en fase 1 del generador</div>
                                <div class="right">{{ voltageL3.lastData?.value || 0}} V</div>
                            </div>
                        </div>

                        <div class="data-grid" *ngIf="circle">
                            <div class="grid-item">
                                <div class="left">Frequencia del generador</div>
                                <div class="right">{{ frequency.lastData?.value || 0}} Hz</div>
                            </div>
                            <div class="grid-item">
                                <div class="left">CFE</div>
                                <div class="right">{{ cfe.lastData?.value || 0}} V</div>
                            </div>
                            <div class="grid-item">
                                <div class="left">Revoluciones por minuto</div>
                                <div class="right">{{ rpm.lastData?.value || 0}}</div>
                            </div>
                            <div class="grid-item">
                                <div class="left">Voltaje de bateria del generador</div>
                                <div class="right">{{ voltageBattery.lastData?.value || 0}} V</div>
                            </div>
                            <div class="grid-item">
                                <div class="left">Temperatura de refrigerante</div>
                                <div class="right">{{ temp.lastData?.value || 0}} V</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
</div>