import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'atm-status-widget',
  templateUrl: './atm-status-widget.component.svg',
  styleUrls: ['./atm-status-widget.component.scss']
})
export class AtmStatusWidgetComponent implements OnInit {
  @Input() size!: string;
  @Input() batteryStatus!: string;
  @Input() sensorNet!: string;
  @Input() sensorFakeDoor!: string;
  @Input() sensorVault!: string;
  @Input() cpuDoorStatus!: string;
  @Input() sensorTray!: string;


  constructor(){
    console.log(this.batteryStatus)
  }

  ngOnInit(): void {
    console.log(this.batteryStatus)
  }

}
