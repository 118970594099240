<div class="login">
    <div class="card">
        <div class="card__text">Bienvenido</div>
        <form class="form">
            <mat-form-field class="example-full-width">
                <mat-label>E-mail</mat-label>
                <input [(ngModel)]="userEmail" matInput type="email" autocomplete="true" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>Password</mat-label>
                <input [(ngModel)]="userPassword" matInput type="password" autocomplete="true" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
        </form>
        <div class="actions">
            <button mat-flat-button color="primary" class="actions__btnS" (click)="signIn()">Ingresar</button>
            <a href="" class="actions__btnF">Olvidé mi contraseña</a>
        </div>
    </div>
    <img class="card__atm" src="../../../assets/images/CAJERO_HOME.png" alt="">
    <img class="card__logo" src="../../../assets/images/LOGO FONDO CLARO.svg" alt="">
    <img src="../../../assets/images/FONDO HOME.png" alt="" class="login__map">
 </div>
