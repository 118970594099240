<div class="widget-main-container h1">
    <div class="title">UBICACIÓN {{ shopData.name }}</div>
    <div class="content relative">
      <div [ngClass]="['ekt-loader', !loading ? 'hide' : '']">
        <div></div>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
      <div class="left">
        <p style="margin:8px auto;">
          Ubicación: <b>{{ shopData.shop_number }}</b>
        </p>
        <div class="direction">
          <div class="location-icon"></div>
          <div class="text">{{ shopData.address }}</div>
        </div>
      </div>
      <div class="right pointer" (click)="openMap()"></div>
    </div>
  </div>
  