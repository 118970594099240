import { variable } from "src/app/interfaces/variable";

export class VariablesService {
  public name!: String;
  public description!: String;
  public id!: String;
  public deviceId!: String;
  public lastData!: any;
  public status!: String;

  constructor(sensor: variable) {
    this.name = sensor.name;
    this.description = sensor.description;
    this.id = sensor.id;
    this.deviceId = sensor.deviceId;

  }

  public setData(value: any){
    this.lastData = {value: value.V, date: new Date()}
  }

  public checkData(){
    if(this.name == "Voltage_DC") {
      if(this.lastData.value < 1) this.status = "error"
      else this.status = "ok"
    }else this.status = "ok"
  }
}