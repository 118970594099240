<div class="container_monitoring_filter">
  <div class="container_monitoring_filter_container">
    <div class="state_name">
      <b>{{stateId | stateIdToFullName}}</b>
    </div>
    <div class="filter_bar">
      <input type="text" #inputValue (input)="filtroTienda(inputValue.value)" [formControl]="arregloNew"
        placeholder="No. Sucursal" autocomplete="off" required>
      <button mat-button class="btnSearch"><mat-icon><img
            src="../../../assets/icons/filtro_buscador.svg"></mat-icon></button>
    </div>
  </div>
</div>
<div class="container_filter_invalidcaracter" *ngIf="arregloNew.status == 'INVALID' && inputValue.value.length >= 1">Ingrese un valor numérico [0-9]</div>
<br>
<div class="container_monitoring">
  <div class="container_monitoring_cards">
    <div *ngIf="auxShop.length >= 1">
      <div class="container_monitoring_cards_card general-card" *ngFor="let shop of auxShop">
        <div class="container_monitoring_cards_card_num">
          {{shop.shop_number}}
        </div>
        <div class="container_monitoring_cards_card_name">
          {{shop.name}}
        </div>
        <div class="container_monitoring_cards_card_addr">
          {{shop.address}}
        </div>
        <div class="container_monitoring_cards_card_btn">
          <button class="container_monitoring_cards_card_btn_btn" mat-flat-button
            (click)="goToBranch(shop.shop_number)">Ver</button>
        </div>
        <div class="container_monitoring_cards_card_status">
          <div class="container_monitoring_cards_card_status_indicator">
          </div>
        </div>
      </div>
    </div>
    <div class="container_monitoring_cards_card_nodata_filter" *ngIf="auxShop.length == 0">
      No se encontraron coincidencias
    </div>
  </div>
</div>