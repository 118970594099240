import { Component, Input, OnInit } from '@angular/core';
import { Chart, registerables } from 'chart.js/auto';
@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit {
  @Input() canvasId!: string;
  @Input() minutes: number = 15;
  @Input() set dataPoint(dataPoint: { value: any; date: Date }) {
    if (this.chart) {
      const hours = dataPoint.date.getHours();
      const minutes = dataPoint.date.getMinutes();
      const strHours = hours < 10 ? '0' + hours : hours;
      const strMinutes = minutes < 10 ? '0' + minutes : minutes;
      this.chart.data.labels?.push(strHours + ':' + strMinutes);
      this.chart.data.datasets[0].data.push(dataPoint.value<1?0:dataPoint.value);
      this.calcPointRadius();
    }
  }
  chart!: Chart;
  variablesTracker: any;
  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.createChart();
  }

  createChart(): void {
    const ctx = document.getElementById(this.canvasId) as HTMLCanvasElement;
    const backgroundColorPlugin = {
      id: 'backgroundColorPlugin',
      beforeDraw: (chart: { ctx: any; chartArea: any; }) => {
        const { ctx, chartArea } = chart;
        if (chartArea) {
          ctx.save();
          ctx.fillStyle = '#f3f3f3';
          ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
          ctx.restore();
        }
      }
    };
    Chart.register(backgroundColorPlugin);
    this.chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: ['4:20', '4:21', '4:22', '4:23', '4:24', '4:25'],
        datasets: [
          {
            label: '',
            data: [0, 1, 1, 0, 1, 0, 1],
            fill: true,
            backgroundColor: this.createGradient(),
            borderColor: '$primaryBlack',
            pointBorderColor: '#000',
            borderWidth: 0.5,
            pointBackgroundColor: '#000',
            pointBorderWidth: 3,
            tension: 0.3,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: false,
            },
          },
          x: {
            grid: {
              display: false,
            },
            ticks: {
              callback: function (value, index, values) {
                const totalLabels = values.length;
                if (
                  index === 0 ||
                  index === Math.floor(totalLabels / 2) ||
                  index === totalLabels - 1
                ) {
                  return this.getLabelForValue(Number(value));
                }
                return '';
              },
              maxRotation: 0,
              autoSkip: false,
            },
          },
        },
      },
    });
    this.calcPointRadius();
  }

  createGradient() {
    const ctx: CanvasRenderingContext2D = document
      .createElement('canvas')
      .getContext('2d')!;
    const gradient = ctx.createLinearGradient(0, 0, 0, 350);
    gradient.addColorStop(0, 'rgba(255, 213, 14, .5)'); // Color inicial del gradiente
    gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');

    return gradient;
  }
  destroyChart(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  /**
   * Funcion para calcular el radio del punto de datasets
   */
  calcPointRadius() {
    const numericData = this.chart.data.datasets[0].data.filter(
      (value: any) => typeof value === 'number'
    ) as number[];
    const maxData = Math.max(...numericData);
    const minData = Math.min(...numericData);
    const yMax = maxData + 0.2;
    const yMin = minData - 0;
    this.chart.options.scales!['y']!.min = yMin;
    this.chart.options.scales!['y']!.max = yMax;
    if (this.chart) {
      const maxLength = Math.max(
        ...this.chart.data.datasets.map((dataset: any) => dataset.data.length)
      );
      if (maxLength > 151) {
        this.setPointRadius(0);
      } else if (maxLength > 116) {
        this.setPointRadius(1);
      } else if (maxLength > 81) {
        this.setPointRadius(2);
      } else {
        this.setPointRadius(3);
      }
      this.chart.update();
    }
  }

  setPointRadius(radius: number) {
    for (let dataset of this.chart.data.datasets) {
      (dataset as any).pointRadius = radius;
    }
  }

  ngOnDestroy() {
    this.unsubscribeMQTT();
}

unsubscribeMQTT() {
  if (this.variablesTracker) 
      this.variablesTracker.destroy();
  this.variablesTracker = null;
}
}
