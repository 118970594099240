import { Component, Input, OnInit } from '@angular/core';
import { Chart, registerables } from 'chart.js/auto';
import * as API from 'aws-amplify/api';
import * as Auth from 'aws-amplify/auth';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit {
  @Input() canvasId!: string;
  @Input() minutes: number = 15;
  @Input() set variableId(id: any){
    if(id){
      this.getDataforVariable(id)
    }
  } 
  @Input() set dataPoint(dataPoint: { value: any; date: Date }) {
    if (this.chart) {
      const hours = dataPoint.date.getHours();
      const minutes = dataPoint.date.getMinutes();
      const strHours = hours < 10 ? '0' + hours : hours;
      const strMinutes = minutes < 10 ? '0' + minutes : minutes;
      this.chart.data.labels?.push(strHours + ':' + strMinutes);
      this.chart.data.datasets[0].data.push(dataPoint.value < 1 ? 0 : dataPoint.value);
      this.calcPointRadius();
    }
  }
  chart!: Chart;
  variablesTracker: any;
  data!: any[];
  date!: any[];
  mask: Boolean = false;

  auxDate = new Date();
  auxDate1 = new Date();
  auxDate2 = new Date();
  auxDate3 = new Date();
  auxDate4 = new Date();
  

  constructor(){
    this.auxDate.setMinutes(this.auxDate.getMinutes() - 1)
    this.auxDate1.setMinutes(this.auxDate.getMinutes() - 2)
    this.auxDate2.setMinutes(this.auxDate.getMinutes() - 3)
    this.auxDate3.setMinutes(this.auxDate.getMinutes() - 4)
    this.auxDate4.setMinutes(this.auxDate.getMinutes() - 5)
  }
  async ngOnInit(): Promise<void> { 
  }

  ngAfterViewInit(): void {
    this.createChart();
  }

  createChart(): void {
    const ctx = document.getElementById(this.canvasId) as HTMLCanvasElement;
    const backgroundColorPlugin = {
      id: 'backgroundColorPlugin',
      beforeDraw: (chart: { ctx: any; chartArea: any; }) => {
        const { ctx, chartArea } = chart;
        if (chartArea) {
          ctx.save();
          ctx.fillStyle = '#f3f3f3';
          ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
          ctx.restore();
        }
      }
    };
    Chart.register(backgroundColorPlugin);
    this.chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: [],
        datasets: [
          {
            label: '',
            data: [],
            fill: true,
            backgroundColor: this.createGradient(),
            borderColor: '$primaryBlack',
            pointBorderColor: '#000',
            borderWidth: 0.5,
            pointBackgroundColor: '#000',
            pointBorderWidth: 3,
            tension: 0.3,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: false,
            },
          },
          x: {
            grid: {
              display: false,
            },
            ticks: {
              callback: function (value, index, values) {
                const totalLabels = values.length;
                if (
                  index === 0 ||
                  index === Math.floor(totalLabels / 2) ||
                  index === totalLabels - 1
                ) {
                  return this.getLabelForValue(Number(value));
                }
                return '';
              },
              maxRotation: 0,
              autoSkip: false,
            },
          },
        },
      },
    });
    this.calcPointRadius();
  }

  createGradient() {
    const ctx: CanvasRenderingContext2D = document
      .createElement('canvas')
      .getContext('2d')!;
    const gradient = ctx.createLinearGradient(0, 0, 0, 350);
    gradient.addColorStop(0, 'rgba(255, 213, 14, .5)');
    gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');

    return gradient;
  }
  destroyChart(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  /**
   * Funcion para calcular el radio del punto de datasets
   */
  calcPointRadius() {
    const numericData = this.chart.data.datasets[0].data.filter(
      (value: any) => typeof value === 'number'
    ) as number[];
    const maxData = Math.max(...numericData);
    const minData = Math.min(...numericData);
    const yMax = maxData + 0.2;
    const yMin = minData - 0;
    this.chart.options.scales!['y']!.min = yMin;
    this.chart.options.scales!['y']!.max = yMax;
    if (this.chart) {
      const maxLength = Math.max(
        ...this.chart.data.datasets.map((dataset: any) => dataset.data.length)
      );
      if (maxLength > 151) {
        this.setPointRadius(0);
      } else if (maxLength > 116) {
        this.setPointRadius(1);
      } else if (maxLength > 81) {
        this.setPointRadius(2);
      } else {
        this.setPointRadius(3);
      }
      this.chart.update();
    }
  }

  setPointRadius(radius: number) {
    for (let dataset of this.chart.data.datasets) {
      (dataset as any).pointRadius = radius;
    }
  }

  ngOnDestroy() {
    this.unsubscribeMQTT();
  }

  unsubscribeMQTT() {
    if (this.variablesTracker)
      this.variablesTracker.destroy();
    this.variablesTracker = null;
  }

  async getDataforVariable(id: any){
    this.mask = true;
    const authToken = (await Auth.fetchAuthSession()).tokens?.idToken?.toString()!;
    API.get({ apiName: "ATMApi", path: `/historical/data/${id}`, options: { headers: { Authorization: authToken }, queryParams: { minutes: "15" } } }).response.then(async res => {
      let { datas }:any = (await res.body.json())
      this.chart.data.labels =  datas.map((res: any) => `${new Date(res.date).getHours()}:${new Date(res.date).getMinutes()}`)
      this.chart.data.datasets[0].data = datas.map((res: any) => res.value)

      this.calcPointRadius();
      this.chart.update();
      this.mask = false;
    })
  }
}
