import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainDashboardComponent } from './components/main-dashboard/main-dashboard.component';
import { MonitoringComponent } from './components/monitoring/monitoring.component';
import { appGuard } from './app.guard';
import { LoginComponent } from './components/login/login.component';
import { MapDashboardComponent } from './components/map-dashboard/map-dashboard.component';


const routes: Routes = [
  { path: "login",component: LoginComponent, pathMatch: "full" },
  { path: "monitoring/:stateId/branches", canActivate: [appGuard], component: MainDashboardComponent, pathMatch: "full" },
  { path: "monitoring/:stateId/:branchId", canActivate: [appGuard], component: MonitoringComponent, pathMatch: "full" },
  { path: "map-dashboard", canActivate: [appGuard], component: MapDashboardComponent, pathMatch: "full" },
  { path: "map-dashboard/:stateId", canActivate: [appGuard], component: MapDashboardComponent, pathMatch: "full" },
  { path: "", redirectTo: "/map-dashboard", pathMatch: "full" },
  { path: "**", redirectTo: "/map-dashboard", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
