import { Subject } from 'rxjs';
import { VariablesService } from '../variables/variables.service';
import { PubSub } from '@aws-amplify/pubsub';
import { environment } from 'src/enviroments/envitoment';

export class MqttService {
  private variables: VariablesService[];
  mqttOberver: Subject<any> = new Subject();

  constructor(variables: VariablesService[]) {

    console.log("mqtt", variables)
    this.variables = variables;
    this.startCommunication();

  }

  mqttSubscription(fn: any){
    return this.mqttOberver.subscribe(fn)
  }

  mqttCloseSubscription(){
    this.mqttOberver.complete();
  }

  startCommunication(){
    let topics = this.variables.map(variable => `data/ekt-energy/${variable.deviceId}`);
    console.log(topics, `wss://${environment.iot_host}/mqtt`)
    new PubSub({region: "us-east-1", endpoint: `wss://${environment.iot_host}/mqtt`}).subscribe({topics: topics}).subscribe((response: any) => {
      let sensorData = this.variables.find(variable => variable.id == response.S);
      if(sensorData){
        sensorData.setData(response)
        this.mqttOberver.next(response); 
      }
    })
  }
}
