<div class="general-status">
  <div class="titles-container">
    <h1 class="title-atm">
      <span class="text">{{ atmName }}</span>
    </h1>
    <h1 class="title-graph">
      <span class="text">{{ title }}</span>
    </h1>
  </div>
  <div class="general-status-container">
    <div class="general-card monitoring-atm" [ngClass]="isAlarma?'alarm-card':''">
      <div class="atm-radar-container">
        <div class="atm-widget">
          <atm-status-widget></atm-status-widget>
        </div>
        <div class="radar-widget">
          <widget-radar [sensorInput]="sensorRadar.lastData?.value" [equipmentId]="equipmentId.split(':')[1] + '-radar'"></widget-radar>
        </div>
      </div>
      <div class="symbols-container">
        <div class="top-icons-box">
          <img *ngIf="false" src="../../../assets/icons/person-counter.png" alt="person-counter" class="symbol-icon">
          <span *ngIf="false" matBadge="3" matBadgeSize="small" matBadgeColor="primary"></span>
          <img *ngIf="false" src="../../../assets/icons/shield.png" alt="shield" class="symbol-icon shield-icon">
        </div>
        <img *ngIf="false" src="../../../assets/icons/balaclava.png" alt="persona-con-balaclava" class="symbol-icon">
        <img *ngIf="false" src="../../../assets/icons/pistola.png" alt="pistola" class="symbol-icon">
        <img *ngIf="false" src="../../../assets/icons/bateria-de-coche.png" alt="bateria-de-coche" class="symbol-icon">
        <img *ngIf="false" src="../../../assets/icons/tanque.png" alt="tanque" class="symbol-icon">
        <img *ngIf="false" src="../../../assets/icons/damage-to-atm.png" alt="damage-to-atm" class="symbol-icon">
        <img *ngIf="false" src="../../../assets/icons/fire.png" alt="fire" class="symbol-icon">
        <img *ngIf="false" src="../../../assets/icons/spray-can.png" alt="spray-can" class="symbol-icon">
        <img *ngIf="false" src="../../../assets/icons/person-return.png" alt="person-return" class="symbol-icon">
        <img *ngIf="false" src="../../../assets/icons/hack-hard-drive.png" alt="hack-hard-drive" class="symbol-icon">
        <img *ngIf="false" src="../../../assets/icons/towed-car.png" alt="towed-car" class="symbol-icon">
        <img *ngIf="false" src="../../../assets/icons/bomb.png" alt="bomb" class="symbol-icon">
        <img *ngIf="false" src="../../../assets/icons/explosion.png" alt="explosion" class="symbol-icon">
      </div>
    </div>
    <div class="general-card monitoring-data" [ngClass]="isAlarma?'alarm-card':''">
      <div class="monitoring-data-chart">
        <div class="monitoring-data-chart-chart">
          <div *ngIf="showBatery" class="monitoring-chart">
            <app-chart-dashboard [data]="sensorBattery?.lastData" [equipmentId]="equipmentId + 'battery'"></app-chart-dashboard>
          </div>
          <div *ngIf="showNet" class="monitoring-chart">
            <app-chart-dashboard [data]="sensorNet?.lastData" [equipmentId]="equipmentId + 'net'"></app-chart-dashboard>
          </div>
          <div *ngIf="showGate" class="monitoring-chart">
            <app-chart-dashboard [data]="sensorVault?.lastData" [equipmentId]="equipmentId + 'vault'"></app-chart-dashboard>
          </div>
          <div *ngIf="showCase" class="monitoring-chart">
            <app-chart-dashboard [data]="sensorTray?.lastData" [equipmentId]="equipmentId + 'tray'"></app-chart-dashboard>
          </div>
          <div *ngIf="showFakeDoor" class="monitoring-chart">
            <app-chart-dashboard [data]="sensorFakeDoor?.lastData" [equipmentId]="equipmentId + 'tray'"></app-chart-dashboard>
          </div>
          <div *ngIf="showMap" class="monitoring-map-chart">
            <app-location-map [long]="sensorGeo?.lastData?.value.split('&')[1]" [lat]="sensorGeo?.lastData?.value.split('&')[0]"
              [canvasId]="'map-' + equipmentId"></app-location-map>
          </div>
        </div>
      </div>
    </div>
    <div class="general-card monitoring-sensors" [ngClass]="isAlarma?'alarm-card':''">
      <div class="monitoring-sensors-sensor">
        <app-sensors
          (sensorClicked)="handleSensorClicked($event)"
          [gpsStatus]="isAlarma"
          [batteryStatus]="_equipmentInfo.id =='equipos:1717624823201' ?'ok':sensorBattery?.lastData?.value>=3?'ok':sensorBattery?.lastData?.value<3&&sensorBattery?.lastData?.value>=0?'error':''"
          [netStatus]="_equipmentInfo.id =='equipos:1717624823201' ?'ok':sensorNet?.lastData?.value==0?'ok':sensorNet?.lastData?.value==1?'error':''"
          [fakeDoorStatus]="_equipmentInfo.id =='equipos:1717624823201'?'ok':sensorFakeDoor?.lastData?.value==0?'ok':sensorFakeDoor?.lastData?.value==1?'error':''"
          [VaultStatus]="_equipmentInfo.id =='equipos:1717624823201' ?'ok':sensorVault?.lastData?.value==0?'ok':sensorVault?.lastData?.value==1?'error':''"
          [cpuDoorStatus]="_equipmentInfo.id =='equipos:1717624823201'?'ok':sensorTray?.lastData?.value==0?'ok':sensorTray?.lastData?.value==1?'error':''"
          [equipmentInfo]="_equipmentInfo"
          [sensorsTitle]="sensors"
          [atmName] = "atmName"
        ></app-sensors>
      </div>
    </div>
  </div>
</div>