import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'widget-radar',
  templateUrl: './radar.component.svg',
  styleUrls: ['./radar.component.scss'],
})
export class RadarComponent implements OnInit {
  @Input() equipmentId!: string;
  @Input() set sensorInput(input: number) {
    this.other()
    this._sensorInput = input;
    if (this.intervalTime) this.setParameters();
    this.radarLevel = this.getTransformSensorInputToGraphLevel(
      this._sensorInput
    );
    this.highlightLevel();
  }
  radarLevel!: number;
  _sensorInput!: number;

  intervalTime!: NodeJS.Timeout;
  upperLimit: any;

  constructor() { }

  ngOnInit(): void {
    if (this._sensorInput) {
      this.radarLevel = this.getTransformSensorInputToGraphLevel(
        this._sensorInput
      );
      this.highlightLevel();
    }
  }

  highlightLevel() {
    this.upperLimit = this.radarLevel;

    let index = 1;
    let isHighlighted = false;
    const flickerNext = () => {
      if (!isHighlighted) {
        if (index <= this.upperLimit) {
          const pathElement = document.querySelector(
            `#lvl-${index}-${this.equipmentId}`
          ) as SVGPathElement | null;

          if (pathElement) {
            pathElement.classList.toggle('highlight');
            index++;
          }
        } else {
          isHighlighted = true;
          index = 1; // Reset index to 1 after finishing all levels
        }
      } else {
        // Un-highlight all elements
        this.other()
        // Reset state for next cycle
        isHighlighted = false;
        index = 1;
      }
    };

    this.addPositionItemsToRadar();
    this.initIntervalTime(flickerNext);
  }

  other(){
    for (let i = 1; i <= 11; i++) {
      const pathElement = document.querySelector(
        `#lvl-${i}-${this.equipmentId}`
      ) as SVGPathElement | null;
      if (pathElement) {
        pathElement.classList.remove('highlight');
      }
    }
  }

  getTransformSensorInputToGraphLevel(input: number): number {
    if (input < 25 || input > 300) {
      // throw new Error(
      //   'Error: La entrada de datos del sensor debe ser entre 25 y 300cm'
      // );
      // console.log('Error: La entrada de datos del sensor debe ser entre 25 y 300cm')
    }

    if (input === 25) {
      return 1;
    }

    return Math.ceil((input - 25) / 27.5);
  }

  initIntervalTime(fn: any) {
    this.intervalTime = setInterval(fn, 100);
  }

  clearIntervalTime() {
    clearInterval(this.intervalTime);
  }

  setParameters() {
    let dots = document.getElementsByClassName('dot-radar-position');
    if (dots && dots.length) {
      for (let i = 0; i <= dots.length; i++) {
        dots[i].remove();
      }
    }

    let rectLbls = document.getElementsByClassName('rect-radar-position');
    if (rectLbls && rectLbls.length) {
      for (let i = 0; i <= rectLbls.length; i++) {
        rectLbls[i].remove();
      }
    }

    let textLbls = document.getElementsByClassName('text-radar-position');
    if (textLbls && textLbls.length) {
      for (let i = 0; i <= textLbls.length; i++) {
        textLbls[i].remove();
      }
    }

    if (this.upperLimit) {
      for (let i = 1; i <= this.upperLimit; i++) {
        const pathElement = document.querySelector(
          `#lvl-${i}`
        ) as SVGPathElement | null;

        if (pathElement) pathElement.classList.remove('highlight');
      }
    }
    this.clearIntervalTime();
  }

  /**
   * Function encargada de regresar el valor del parámetro a establecer en el atributo de distancia para pintar
   * el punto (distancia) retornada por el sensor de distancia en el radar del ATM
   * el valor mínimo es 25cm, el máximo es 300cm
   */
  getDotDistancePosition(input: number): string {
    switch (input) {
      case 1:
        return '26';
      case 2:
        return '53';
      case 3:
        return '79';
      case 4:
        return '105';
      case 5:
        return '132';
      case 6:
        return '158';
      case 7:
        return '184';
      case 8:
        return '210';
      case 9:
        return '236';
      case 10:
        return '262';
      default:
        return '1000';
    }
  }

  /**
   * Método para agregar los items para mostrar en el radar
   */
  addPositionItemsToRadar() {
    let dotPosition = this.getDotDistancePosition(this.radarLevel);
    const svg = document.querySelector('#radar-' + this.equipmentId);
    if (!svg) {
      return;
    }

    const dot = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'circle'
    );
    dot.setAttribute('cx', dotPosition);
    dot.setAttribute('cy', '165');
    dot.setAttribute('r', '12');
    dot.classList.add('dot-radar-position');

    dot.addEventListener('mouseover', () => {
      rect.setAttribute('visibility', 'visible');
      text.setAttribute('visibility', 'visible');
    });

    dot.addEventListener('mouseout', () => {
      rect.setAttribute('visibility', 'hidden');
      text.setAttribute('visibility', 'hidden');
    });

    svg.appendChild(dot);

    const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    let rectLblXPosition: string;

    if (this.radarLevel < 8) {
      rectLblXPosition = dotPosition;
    } else {
      rectLblXPosition = String(Number(dotPosition) - 80);
    }
    rect.setAttribute('x', rectLblXPosition);
    rect.setAttribute('y', '100');
    rect.setAttribute('width', '70');
    rect.setAttribute('height', '45');
    rect.setAttribute('fill', 'black');
    rect.setAttribute('visibility', 'hidden');
    rect.classList.add('rect-radar-position');
    svg.appendChild(rect);

    const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    let textLblXPosition;

    if (this.radarLevel > 2) {  
      //Cambiamos la position del text para centrar el texto en el label
      textLblXPosition = String(Number(rectLblXPosition) + 10);
    } else {
      textLblXPosition = String(Number(rectLblXPosition) + 15);
    }
    text.setAttribute('x', textLblXPosition);
    text.setAttribute('y', '130');
    text.setAttribute('fill', 'white');
    text.textContent = `${this._sensorInput} cm`;
    text.setAttribute('visibility', 'hidden');
    text.classList.add('text-radar-position');

    svg.appendChild(text);
  }
}
