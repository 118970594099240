import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-title-icon',
  templateUrl: './title-icon.component.html',
  styleUrls: ['./title-icon.component.scss']
})
export class TitleIconComponent {
  @Input() iconSrc!: string;
  @Input() title!: string;
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  ICONS_PATH: string = "../../../assets/icons/"

  ngOnInit(): void {
    if(this.iconSrc){
      this.iconSrc = this.ICONS_PATH + this.iconSrc;
    }
  }
}
