<div *ngIf="isAlarm">
  <div class="children1"></div>
  <div class="children2"></div>
  <div class="children3"></div>
  <div class="children4"></div>
</div>

<app-mask [mask]="mask"></app-mask>

<div class="container" [ngClass]="{ 'full-container-map-dash': !isStateSelected }">

  <div class="map-container" [@mapAnimation]>
    <app-map-widget *ngIf="!mask" id = "map" [territoriesAvailables]="territoriesAvailables" (stateEvent)="handleStateSelectedEvent($event)" [paintAlertedStates] = "isAlarm"></app-map-widget>
  </div>

  <div *ngIf="isStateSelected && !mask" class="info-container" [@dataAnimation]>
    <div class="state-info">

      <app-title-icon [iconSrc]="'UBICACION.png'" [title]="stateId | stateIdToFullName" [size]="'medium'"></app-title-icon>

      <div class="info-cards-container">
        <div class="general-card">
          <div>NO. SUCURSALES</div> <hr />
          <div class="count-number">{{shops}}</div>
        </div>
        <div class="general-card">
          <div>NO. CAJEROS</div> <hr />
          <div class="count-number">{{atms}}</div>
        </div>
        <!-- <div class="general-card">
          <div>ATM ALERTADOS</div> <hr />
          <div class="count-number">0</div>
        </div> -->
      </div>

    </div>

    <!-- <div class="state-alerts-container">

      <app-title-icon [iconSrc]="'ALARMA.png'" [title]="'ALERTAS'" [size]="'medium'"></app-title-icon>

      <div class="general-card">
        <table>
          <thead>
            <tr>
              <th>HORA</th>
              <th>EQUIPO</th>
              <th class="th-descripcion">DESCRIPCIÓN</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>03:25</td>
              <td>00001</td>
              <td>Bóveda abierta</td>
            </tr>
            <tr>
              <td>04:25</td>
              <td>00002</td>
              <td>Bóveda abierta</td>
            </tr>
            <tr>
              <td>05:25</td>
              <td>00003</td>
              <td>Bóveda abierta</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div> -->
    <br><br><br><br><br>
    <div class="state-button">
      <button mat-button class="state-button-button" (click)="goToState()">
        <b>Ver Estado</b>
      </button>
    </div>

  </div>
  <div *ngIf="showAlarmedATMs && !isStateSelected" class="alarmed-atms-container" [@dataAnimation]>
    <div class="general-card">

      <app-title-icon [iconSrc]="'UBICACION.png'" [title]="'ATM 2'" [size]="'small'"></app-title-icon>

      <button mat-button class="state-button-button" (click)="goToAtm('cdmx', '542')">
        <b>Ver ATM</b>
      </button>

    </div>
  </div>

</div>
