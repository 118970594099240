import { VariablesManagerService } from "../variables/variables-manager.service";
import { VariablesService } from "../variables/variables.service";
import { variable } from 'src/app/interfaces/variable';
import * as API from 'aws-amplify/api';
import * as Auth from 'aws-amplify/auth';


export class AtmEquipmentService {
  name!: string;
  description!: string;
  id!: string;
  senssoraux!: VariablesManagerService;
  parent!: string;
  sensors!: any;

  //SENSORS
  vaultSensor!: any;
  sensorRadar!: any;
  sensorBattery!: any;
  sensorGeo!: any;
  sensorNet!: any;
  sensorFakeDoor!: any;
  sensorVault!: any;
  sensorTray!: any;
  
  reports: {countOpenVaul: number, hoursActive: number, hoursInactive: number, numberTransactions: number, timeForUser: number} = {
    countOpenVaul: 0,
    hoursActive: 0,
    hoursInactive: 0,
    numberTransactions: 0,
    timeForUser: 0
  };

  error: any = {type: "", status: false};


  constructor(atm: any) {
    this.name = atm.name;
    this.description = atm.description;
    this.id = atm.id;
    this.parent = atm.parent;
    this.sensors = (new VariablesManagerService(atm.sensors)).variables

    this.sensorRadar = this.sensors.find((sensor: variable) => sensor.name == "Presencia_cm" && sensor.id == sensor.id)
    this.sensorGeo = this.sensors.find((sensor: variable) => sensor.name == "Latitud_y_Longitud" && sensor.id == sensor.id)
    this.sensorBattery = this.sensors.find((sensor: variable) => sensor.name == "Voltage_DC" && sensor.id == sensor.id)
    this.sensorNet = this.sensors.find((sensor: variable) => sensor.name == "Cajero Operando" && sensor.id == sensor.id)
    this.sensorFakeDoor = this.sensors.find((sensor: variable) => sensor.name == "Puerta principal" && sensor.id == sensor.id)
    this.sensorVault = this.sensors.find((sensor: variable) => sensor.name == "Boveda" && sensor.id == sensor.id)
    this.sensorTray = this.sensors.find((sensor: variable) => sensor.name == "Bandeja" && sensor.id == sensor.id)

    this.getReport("hour")


  }

  public SelectVariable(){
    this.sensors.forEach((sensor: variable) => {
      if(sensor.selectVariable && sensor.selectVariable == true) sensor.selectVariable = false
    })
  }

  public async getReport(type: string){
    if(this.sensorRadar && this.sensorVault){
      let arrayData = [
        {sensorId: this.sensorRadar?.id, type: "presencia"},
        {sensorId: this.sensorVault?.id, type: "boveda"}
      ]
      const authToken = (await Auth.fetchAuthSession()).tokens?.idToken?.toString()!;
      const restOperation = (await API.post({ apiName: "ATMApi", path: `/branches/get-report`, options: { headers: { Authorization: authToken }, body: { sensors: arrayData, time: type } } }).response).body
      restOperation.json().then((response: any) => {
          this.reports = response.response
          if(this.reports){
            this.reports.hoursActive = Number(this.reports?.hoursActive.toFixed(1))
            this.reports.hoursInactive = Number(this.reports?.hoursInactive.toFixed(1))
            this.reports.timeForUser = this.reports?.timeForUser && String(this.reports?.timeForUser).length > 1 ? Number(this.reports?.timeForUser.toFixed(1)) : this.reports?.timeForUser
          }
          
      })
    }

    
  }
}
