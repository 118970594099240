import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators } from '@angular/forms';
import { AlarmsService } from 'src/app/services/mqtt/alarms.service';
import * as API from 'aws-amplify/api';
import * as Auth from 'aws-amplify/auth';

@Component({
  selector: 'app-main-dashboard',
  templateUrl: './main-dashboard.component.html',
  styleUrls: ['./main-dashboard.component.scss'],
})
export class MainDashboardComponent implements OnInit {
  states = [
    { name: 'CDMX' },
    { name: 'Oaxaca' },
    { name: 'Estado de México' },
    { name: 'Morelos' },
    { name: 'Chiapas' },
    { name: 'Morelos' },
    { name: 'Querétaro' },
    { name: "Sauron Lab", id: "cdmx"}
  ];

  arregloNew = new FormControl('', [
    Validators.required,
    Validators.pattern(/^\d+$/),
  ]);

  dataShops: any =[]
  auxShop: any[]=[]

  mask: Boolean = true;


  constructor(private router: Router, private route: ActivatedRoute, private alarms: AlarmsService) {}
  stateId: string = '';

  async ngOnInit() {
    if (this.route.snapshot.params['stateId']) {
      this.stateId = this.route.snapshot.params['stateId'];
    }

    const state = this.states.filter((state: any) => state.id === this.stateId);

    const authToken = (await Auth.fetchAuthSession()).tokens?.idToken?.toString()!;

    const restOperation = (await API.post({ apiName: "ATMApi", path: "/branches/get-shops", options: { headers: { Authorization: authToken },body: { state: state[0].name } } }).response).body
    restOperation.json().then((data: any) => {
    this.mask=false
    this.dataShops=data.shops
    this.auxShop=this.dataShops
    });
  }

  goToBranch(branchId: string) {
    this.router.navigate(['monitoring', this.stateId, branchId]);
  }

  filtroTienda(inputValue: any) {
    const digitRegExp: RegExp = /^\d+$/;

    if (inputValue.length >= 1 && digitRegExp.test(inputValue)) {
      this.auxShop = this.dataShops.filter((data: { shop_number: string }) => {
        const inputValueRegex = new RegExp(`^${inputValue}`);
        return inputValueRegex.test(data.shop_number.toString());
      });
    }
    if (this.arregloNew.value == '') this.auxShop = this.dataShops;
  }
}
