<div class="monitoring-data-toolbar">
  <div class="monitoring-data-time-selector">
    <div class="select-container">
      <mat-form-field appearance="outline">
        <mat-label>Tiempo mostrado:</mat-label>
        <mat-select [(ngModel)]="indexDate" name="index">
          <mat-option
            *ngFor="let option of timeOptions"
            [value]="option.value"
            >{{ option.text }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="monitoring-data-calendar-dateTime">
    <div class="monitoring-data-calendar" *ngIf="indexDate == -1">
      <mat-form-field appearance="outline">
        <mat-label>Rango de Fechas</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate />
          <input matEndDate />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
  </div>
</div>
<app-chart [dataPoint]="data" [canvasId]="'chart-' + equipmentId" [variableId]="variableId"></app-chart>
