<div *ngIf="isAlarm">
  <div class="children1"></div>
  <div class="children2"></div>
  <div class="children3"></div>
  <div class="children4"></div>
</div>
<div
  class="container"
  [ngClass]="{ 'full-container-map-dash': !isStateSelected }"
>
  <div class="map-container" [@mapAnimation]>
    <app-map-widget
      id = "map"
      [territoriesAvailables]="territories"
      (stateEvent)="handleStateSelectedEvent($event)"
      [paintAlertedStates] = "isAlarm"
    ></app-map-widget>
  </div>
  <div *ngIf="isStateSelected" class="info-container" [@dataAnimation]>
    <div class="state-info">
      <app-title-icon
        [iconSrc]="'UBICACION.png'"
        [title]="stateId | stateIdToFullName"
        [size]="'medium'"
      >
      </app-title-icon>
      <div class="info-cards-container">
        <div class="general-card">
          NO. SUCURSALES
          <hr />
          <div class="count-number">50</div>
        </div>
        <div class="general-card">
          NO. CAJEROS
          <hr />
          <div class="count-number">50</div>
        </div>
        <div class="general-card">
          ATM ALERTADOS
          <hr />
          <div class="count-number">50</div>
        </div>
      </div>
    </div>
    <div class="state-alerts-container">
      <app-title-icon
        [iconSrc]="'ALARMA.png'"
        [title]="'ALERTAS'"
        [size]="'medium'"
      >
      </app-title-icon>
      <div class="general-card">
        <table>
          <thead>
            <tr>
              <th>HORA</th>
              <th>EQUIPO</th>
              <th class="th-descripcion">DESCRIPCIÓN</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>03:25</td>
              <td>00001</td>
              <td>Bóveda abierta</td>
            </tr>
            <tr>
              <td>04:25</td>
              <td>00002</td>
              <td>Bóveda abierta</td>
            </tr>
            <tr>
              <td>05:25</td>
              <td>00003</td>
              <td>Bóveda abierta</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="state-button">
      <button mat-button class="state-button-button" (click)="goToState()">
        <b>Ver Estado</b>
      </button>
    </div>
  </div>
  <div *ngIf="showAlarmedATMs && !isStateSelected" class="alarmed-atms-container" [@dataAnimation]>
    <div class="general-card">
      <app-title-icon
        [iconSrc]="'UBICACION.png'"
        [title]="'ATM 2'"
        [size]="'small'"
      >
      </app-title-icon>
      <button mat-button class="state-button-button" (click)="goToAtm('cdmx', '542')">
        <b>Ver ATM</b>
      </button>
    </div>
  </div>
</div>
