import { Component, Input } from '@angular/core';

@Component({
  selector: 'ekt-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent {
  @Input() shopData: any = {};
  @Input() loading: boolean = true;

  constructor() {}

  ngOnInit() {}

  openMap() {
    let win:any = window.open(`https://www.google.com/maps/place/${this.shopData.map_url}`, "_blank");
    win.focus();
  }
}
