import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as API from 'aws-amplify/api';
import * as Auth from 'aws-amplify/auth';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { AlarmsService } from 'src/app/services/mqtt/alarms.service';

@Component({
  selector: 'app-map-dashboard',
  templateUrl: './map-dashboard.component.html',
  styleUrls: ['./map-dashboard.component.scss'],
  animations: [
    trigger('mapAnimation', []),
    trigger('dataAnimation', [
      state('void', style({ opacity: 0, transform: 'translateX(-100%)' })),
      transition(':enter', [
        animate(
          '500ms ease-out',
          style({ opacity: 1, transform: 'translateX(0)' })
        ),
      ]),
      transition(':leave', [
        animate(
          '500ms ease-in',
          style({ opacity: 0, transform: 'translateX(-100%)' })
        ),
      ]),
    ]),
  ],
})
export class MapDashboardComponent implements OnInit {
  mask: Boolean = false;
  territoriesAvailables = [
    { name: "Sauron Lab", id: "cdmx", available: false}
  ]



  isStateSelected: boolean = false;
  stateId!: string;
  estadoAnimacion = 'normal';
  territories: any;
  shops: any;
  atms: any;

  interval!: any;
  isAlarm: boolean = false;

  showAlarmedATMs: boolean = false;
  lastD!: string;
  audio!: HTMLAudioElement;
  constructor( private router: Router, private route: ActivatedRoute, private el: ElementRef, private alarms: AlarmsService) {}

  async ngOnInit(): Promise<void> {
    if (this.route.snapshot.params['stateId']) {
      this.selectState(this.route.snapshot.params['stateId']);
    }
    this.setData();
    // this.audio = new Audio('assets/audio/soundAlarm.ogg'); 
    // this.audio.loop = true; 
    // this.audio.volume = 0.5; 
    this.alarms.mqttSubscription((result: any) => {

      if (result) {
        // this.lastD = result.V.split(',').join('&');
        clearInterval(this.interval)

        this.setInterval(async () => {
          const authToken = (await Auth.fetchAuthSession()).tokens?.idToken?.toString()!;
          const restOperation = (await API.get({ apiName: "ATMApi", path: `/monitoring/getLatestLog/${result.S}`, options: { headers: { Authorization: authToken } } }).response).body
          restOperation.json().then((territories: any) => {
            // /**Alarma de la geocerca */
            if (territories.message.detail.EventType) {
              let status = territories.message.detail.EventType;
              if (status == "EXIT") {
                this.lastD = territories.message.detail.Position.reverse().join('&');
                this.isAlarm = true;
                this.showAlarmedATMs = true;
                // this.audio.play().catch(error => {  
                // });              
              } else {
                this.isAlarm = false;
                this.showAlarmedATMs = false;
              }
            }
          })
        })
      }

    })
  }

  async setData() {
    this.mask = true;
    const authToken = ( await Auth.fetchAuthSession()).tokens?.idToken?.toString()!;
    await this.getTerritories(authToken);
    this.mask = false;
  }

  async getTerritories(authToken: any){
    let response: any = ( await API.get({ apiName: 'ATMApi', path: '/branches/get-data-initial/', options: { headers: { Authorization: authToken } } }).response).body;
    let { territories } = await response.json()
    this.territoriesAvailables = this.territoriesAvailables.map(tAvailable => {
      territories.forEach((territorie: any) => {
        const name: string = territorie.name;
        if(name == tAvailable.name) tAvailable.available = territorie.available == "available"
      })
      return tAvailable
    })
  }

  handleStateSelectedEvent(stateId: string) {
    this.selectState(stateId);
    this.router.navigate(['/map-dashboard', stateId]);
  }

  async selectState(stateId: string) {
    this.estadoAnimacion = this.estadoAnimacion === 'normal' ? 'modificado' : 'normal';
    let { name }: any = this.territoriesAvailables.find(state => state.id == stateId);
    let shops: any = await this.getShops(name)
    this.shops = shops.shops.length
    this.atms = shops.atms.length

    this.isStateSelected = true;
    this.stateId = stateId;
    const elements = this.el.nativeElement.querySelectorAll(`#${stateId}`);
    elements.forEach((element: HTMLElement) => {
      element.classList.add('selected-state');
    });
  }

  async getShops(stateName: any){
    const authToken = (await Auth.fetchAuthSession()).tokens?.idToken?.toString()!;
    const restOperation = (await API.post({ 
      apiName: "ATMApi", 
      path: "/branches/get-shops",
      options: { 
        headers: { Authorization: authToken },
        body: { state: stateName }
      } 
    }).response).body
    const res = await restOperation.json();

    return res
  }

  goToState() {
    if (this.route.snapshot.params['stateId'])
      this.router.navigate([
        'monitoring',
        this.route.snapshot.params['stateId'],
        'branches',
      ]);
  }

  goToAtm(stateId: string, atmId: string) {
    this.router.navigate([
      'monitoring',
      stateId,
      atmId
      , { status: true, lastD: this.lastD }]);
  }

  setInterval(fn: any) {
    this.interval = setInterval(fn, 100);
  }
}
