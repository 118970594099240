import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stateIdToFullName'
})
export class StateIdToFullNamePipe implements PipeTransform {


  transform(value: string): string {
    const stateAbbreviations: { [key: string]: string } = {
      //Las llaves vienen de los IDs del map-widget SVG
      'aguascalientes': 'Aguascalientes',
      'bcn': 'Baja California',
      'bcs': 'Baja California Sur',
      'campeche': 'Campeche',
      'chiapas': 'Chiapas',
      'chihuahua': 'Chihuahua',
      'cdmx': 'Ciudad de México',
      'coahuila': 'Coahuila',
      'colima': 'Colima',
      'durango': 'Durango',
      'estado_de_mexico': 'Estado de México',
      'guanajuato': 'Guanajuato',
      'guerrero': 'Guerrero',
      'hidalgo': 'Hidalgo',
      'jalisco': 'Jalisco',
      'michoacan': 'Michoacán',
      'morelos': 'Morelos',
      'nayarit': 'Nayarit',
      'nuevo_leon': 'Nuevo León',
      'oaxaca': 'Oaxaca',
      'puebla': 'Puebla',
      'queretaro': 'Querétaro',
      'quintana_roo': 'Quintana Roo',
      'san_luis_potosi': 'San Luis Potosí',
      'sinaloa': 'Sinaloa',
      'sonora': 'Sonora',
      'tabasco': 'Tabasco',
      'tamaulipas': 'Tamaulipas',
      'tlaxcala': 'Tlaxcala',
      'veracruz': 'Veracruz',
      'yucatan': 'Yucatán',
      'zacatecas': 'Zacatecas'
    };

    const fullName = stateAbbreviations[value];
    return fullName ? fullName.toUpperCase() : '';
  }
}
