import { VariablesService } from "../variables/variables.service";
import { VariablesManagerService } from "../variables/variables-manager.service";
import { AtmEquipmentService } from "./atm-equipment.service";
import { Subject } from "rxjs";

export class AtmManagerService {
  atms: any;
  private variablesSubjectATM: Subject<any> = new Subject();

  private _variables!: VariablesManagerService;

  constructor(atms: any[]) {
    this.atms = atms.map((atm: any) => new AtmEquipmentService(atm));

    this._variables = new VariablesManagerService(this.getVariables());

    this._variables.variablesSubscription((variables: any) => {
      this.variablesSubjectATM.next(variables)
    })

  }

  variablesSubscription(fn: any){
    return this.variablesSubjectATM.subscribe(fn)
  }

  getVariables(){
    let variables: VariablesService[] = [];
    this.atms.forEach((atm: any) => {
      variables = variables.concat(atm.sensors || [])
    })
    return variables;
  }


}
