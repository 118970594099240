import { Injectable } from '@angular/core';
import { Amplify } from 'aws-amplify';
import * as auth from 'aws-amplify/auth';
import { AWS_AUTH_CREDENTIALS } from './aws-amplify.config';

@Injectable({
  providedIn: 'root'
})
export class ServiceAmplifyService {
  isLogged: Boolean =  false; 
  user: any;


  constructor() { 
    Amplify.configure(AWS_AUTH_CREDENTIALS);
  }

  async signIn(dataUser: {username: string, password: string}){
    return await auth.signIn(dataUser);
  }

  async currentSession(){
    return await auth.fetchAuthSession();
  }

  async currentSessionUser(){
    return auth.getCurrentUser();
  }

  
}
