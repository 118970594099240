import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pde',
  templateUrl: './pde.component.svg',
  styleUrls: ['./pde.component.scss']
})
export class PdeComponent {
  @Input() active!: boolean;
}
