import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VariablesManagerService } from 'src/app/services/variables/variables-manager.service';
import * as API from 'aws-amplify/api';
import * as Auth from 'aws-amplify/auth';
import { AtmManagerService } from 'src/app/services/equipments/atm-manager.service';

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss']
})
export class MonitoringComponent {

  dataShops: any = [
    { shop_number: 542, name: "Tienda 1", address: "Insurgentes Sur 3579 - Tlalpan, La Joya CP 03100" },
    { shop_number: 2, name: "Tienda 2", address: "Insurgentes Sur 3579 - Tlalpan, La Joya CP 03100" },
    { shop_number: 3, name: "Tienda 3", address: "Insurgentes Sur 3579 - Tlalpan, La Joya CP 03100" },
    { shop_number: 3, name: "Tienda 3", address: "Insurgentes Sur 3579 - Tlalpan, La Joya CP 03100" },
    { shop_number: 3, name: "Tienda 3", address: "Insurgentes Sur 3579 - Tlalpan, La Joya CP 03100" },
    { shop_number: 3, name: "Tienda 3", address: "Insurgentes Sur 3579 - Tlalpan, La Joya CP 03100" }
  ]

  queryParams: any;
  params: any;
  temperatureLimits: any = { min: 17, max: 27 };

  selectedComponent: string | null = 'atm';
  atmDevices!: AtmManagerService;
  pdeDevices: any
  variables: any[] = [];
  variablesService!: VariablesManagerService;

  mask: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute, private cdk: ChangeDetectorRef) {
  }
  async ngOnInit() {
      this.setData();
  }

  async setData() {
    try {
      this.mask = true
      const authToken = (await Auth.fetchAuthSession()).tokens?.idToken?.toString()!;
      let restOperation: any = (await API.get({ apiName: "ATMApi", path: "/branches/get-branches-last-data", options: { headers: { Authorization: authToken } } }).response).body
      restOperation = await restOperation.json();
      const devices = restOperation.branches[0].devices;
      this.atmDevices = new AtmManagerService(devices.filter((device: any) => device.type === 'ATM'));
      this.pdeDevices = devices.filter((device: any) => device.type === 'PDE');
      this.mask = false
    } catch (error) {
      console.log(error)
      this.mask = false
    }
  }

  selectComponent(button: string) {
    this.selectedComponent = button;
  }
}
